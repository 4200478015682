<script>
	import { EmailButton } from './EmailButton.vh';

	export default {
		name: 'email-button',
		components: {
			EmailButton
		},
        methods: {
            launchEmailClient: function() {
                window.open('mailto:explore@k9interactive.com', '_self');
            }
        }
	}

</script>

<template>

	<EmailButton id="mobile-email-button"
        class="ui-active"
        @bevel-bar-clicked="launchEmailClient()">
        
        <FontAwesome class="email-icon" style="pointer-events: none; font-size: 18px; height: 64px; width: 21px; margin-right: 0px; margin-top: 10px !important; color: rgb(0,0,238) !important"
            :icon="['fas', 'envelope']"
        />

	</EmailButton>

</template>