import styled from 'vue-styled-components';

import theme from '@/system/styles/theme';

import { FlipDown } from '@/system/styles/keyframes';

import DepthMap from '@/system/styles/depth-map';
import TimingMap from '@/system/styles/timing-map';

import BevelBar from '@/components/BevelBar.vue';

export var Widgets = {
	TechTab: styled(BevelBar)`
		@keyframes ${FlipDown};
		animation: FlipDown 0.5s forwards;
		transform: rotateX(90deg);
		animation-delay: ${TimingMap.TAB_TECH}s;
		transition: background-color 0.75s;

		position: fixed;
		z-index: ${ DepthMap.TAB_TECH };
		left: 333px;
		top: 0vh;
        height: 50px !important;
        width: 275px !important;
		//min-width: 300px;
		
		overflow: hidden;
		text-transform: uppercase;
		letter-spacing: 8px;
		line-height: 32px;
		font-size: 12px;
		font-weight: bold;
		text-align: center;

		& > .content-area {
			justify-content: center;
			align-items: center;
            //margin-left: -12px;

			/* FENCE: Let raw html elements pass their shape through to svg icons */
			& > a {
				height: 60%;
				width: auto;
				display: flex;
				
				align-items: center;
				justify-content: center;
			}
		
			/* Some fontawesome icons are too big by default, shrink them */
			& > .phone-icon, & > .address-icon {
				height: 55% !important;
			}

			> a > .email-icon, > a > .linked-in-icon, > .address-icon, > .phone-icon {
				height: 100%;
				width: auto;
				
				color: ${ theme.light.glyphColor };
				cursor: pointer;

				&.light {
					color: ${ theme.light.glyphColor };

					&:hover {
						color: blue;
					}
				}

				&.dark {
					color: ${ theme.dark.glyphColor };

					&:hover {
						color: ${ theme.dark.glyphColorHighlight };
					}
				}
			}
		}
	`
};