import styled from 'vue-styled-components';

export var Widgets = {
    ChevronWrapper: styled('div', { align: String })`
        position: absolute;
        bottom: 24px;
        width: 64px;
        height: 64px;
        text-align: center;

        &.visible {
            opacity: 1;
            //display: block;
        }

        &.hidden {
            opacity: 0 !important; // Prioritize readability over vfx
            //display: none;
        }
    `,
    Chevron: styled.img`
        @keyframes Blink {
            0% {
                opacity: 0.25;
            }

            7.5% {
                opacity: 1;
            }

            15% {
                opacity: 0.25;
            }

            // Leave room for the other 2

            100% {
                opacity: 0.25;
            }
        }

        animation: 2s Blink linear infinite;
        transition: 0.25s ease all;
        position: absolute;
        width: 100%;
        height: 33%;
        left: 0px;
        bottom: 0px;
        opacity: 0.25;
        filter: drop-shadow(0px 0px 4px #fff);
    `
}