<script>
	import { PhoneButton } from './PhoneButton.vh';

	export default {
		name: 'phone-button',
		components: {
			PhoneButton
		},
        methods: {
            startPhoneCall() {
                window.open('tel:+2027501699', '_self');
            }
        }
	}

</script>

<template>

	<PhoneButton id="mobile-phone-button"
        class="ui-active"
        @bevel-bar-clicked="startPhoneCall()">

        <FontAwesome class="phone-icon" style="pointer-events: none !important; font-size: 20px; color: rgb(0,0,238) !important"
            :icon="['fas', 'phone']" />

	</PhoneButton>

</template>