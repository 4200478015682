import styled from 'vue-styled-components';
import DepthMap from '@/system/styles/depth-map';

export var FLIP_ANIMATION_DURATION = 1000; // ms
export var BASE_TRANSITION = `all ${FLIP_ANIMATION_DURATION}ms`;

export var Widgets = {
    CardSpace: styled.div`
        /* position: relative; // FENCE: Keep this or the descendants will fight for z-index (browser spec bug) */
        // It appears that only the base (Topic, here) needs position: relative, if it breaks, this is the BUGBUGBUG

        width: 80vw;
        height: 80vw;
        margin-bottom: 40px;
        background-color: transparent;
    `,
    AnimationLayer: styled.div` // FENCE: Perspective consumes the fullscreen context for purposes of Fixed (bug)
        perspective: 1000px;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        pointer-events: none; // Animation layer does not clip

        &.elevated {
            position: fixed;
            z-index: ${DepthMap.FOCUSED_CONTENT};
        }
    `,
    Card: styled.div`
        display: inline-block;
        border: 3px solid #333333;
        font-size: 2.5rem;
        border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
        background: transparent; // FENCE: SAFARI ZONE
        position: relative;

        &:before {
            content: '';
            pointer-events: none !important;
            border: 2px solid #353535;
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.3deg);
            border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
        }

        position: absolute;
        pointer-events: all; // Card clipping reset from AnimationLayer context
        transition: ${BASE_TRANSITION};
        transform-style: preserve-3d;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        text-align: center;

        &.flipped {
            transform: rotateY(180deg);
            width:  90%;
            height: 90%;
            left: 5%;
            top: 5%;
        }
    `,
    Front: styled('div', { image: String })`
        backface-visibility: hidden !important;
        cursor: pointer;
        width: 100%;
        height: 100%;

        background-image: url(${ (props) => props.image });
        border-radius: 1%;
        background-color: #bbb;
        background-size: cover;
        color: black;
    `,
    ClickIndicator: styled.div`
        @keyframes PulseClickIndicator {
            0% {
                opacity: .25;
            }

            50% {
                opacity: .75;
            }

            100% {
                opacity: .25;
            }
        }

        position: absolute;
        left: 20px;
        top: 20px;

        pointer-events: none;
        color: white;
        font-size: 15px;
        border-radius: 100%;

        animation: PulseClickIndicator 3s infinite;

        &.visible {
            display: block;
        }

        &.hidden {
            display: none;
        }
    `,
    Back: styled.div`
        transform: rotateY(180deg);
        backface-visibility: hidden !important;

        display: flex;
        position: absolute;
        top:0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-top: 1px solid #777;
        border-left: 1px solid #555;
        border-right: 1px solid #555;
        border-bottom: 1px solid #222;
        border-radius: 12px;

        background-color: #101010; // charcoal now, was: black;
        user-select: none;
        overflow-y: none;
        color: white;
        overflow: hidden;

        // FENCE: We have to turn this off until it's flipped or it snags the main page scroll
        &.scrollable {
            overflow-y: auto;
        }

        & > div > p {
            margin-top: auto !important;
            margin-bottom: auto !important;

            color: white !important;
            background-color: black !important;
            border-radius: 6px !important;
            font-size: 16px !important;
            line-height: 24px !important;
        }
    `
};