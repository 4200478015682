import styled from 'vue-styled-components';
import BevelBar from '@/components/BevelBar.vue';

export var EmailButton = styled(BevelBar)`
	position: fixed;
    z-index: 9999;
    top: 0px;
    left: -100px;
    width: 200px !important;
    display: none;

    & > .content-area {
        pointer-events: none !important;
        justify-content: flex-end !important;
        align-items: flex-end !important;
        margin-top: 6px;
    }
`;