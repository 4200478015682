<script>
	import { PupBoy, Widgets } from './PupBoy.vh';
	import store from '@/system/store';

	export default {
		name: 'pup-boy',
		components: {
			PupBoy,
            StrategyIcon: Widgets.StrategyIcon,
            DesignIcon: Widgets.DesignIcon,
            TechIcon: Widgets.TechIcon,
            FAQIcon: Widgets.FAQIcon,
            AboutIcon: Widgets.AboutIcon,
            CollapseToggle: Widgets.CollapseToggle
		},
		// data: () => ({
		// }),
		methods: {
            invoke(topic) {
                if (topic === 'strategy') {
                    store.commit('setDesiredTopic', 'topic-strategy');
                } else if (topic === 'design') {
                    store.commit('setDesiredTopic', 'topic-ux');
                } else if (topic === 'tech') {
                    store.commit('setDesiredTopic', 'topic-ui');
                } else if (topic === 'roi') {
                    store.dispatch('toggleROITray');
                } else if (topic === 'about') {
                    store.dispatch('openAboutModal');
                } else {
                    console.error('INVALID TOPIC GIVEN TO FOCUS() IN PUPBOY');
                }

                store.commit('togglePupBoy');
            }
		}
	}

</script>

<template>

	<PupBoy id="pup-boy"
        :class="{open: $store.state.pupBoyOpen}">

		<CollapseToggle @click="$store.commit('togglePupBoy')">
		</CollapseToggle>

        <StrategyIcon @click="invoke('strategy')" />
        <DesignIcon @click="invoke('design')" />
        <TechIcon @click="invoke('tech')" />
        <FAQIcon @click="invoke('roi')" />
        <AboutIcon @click="invoke('about')" />

	</PupBoy>

</template>