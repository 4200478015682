import styled from 'vue-styled-components';

import theme from '@/system/styles/theme.js';

import Modal from './abstract/Modal.vue';

export var StyledModal = styled(Modal)`
    & > * > .modal-container {
        width: 900px !important;
        height: 580px !important;
        border-radius: 48px;

        & > .modal-header {
            border-radius: 40px 40px 0px 0px;
        }
    }
`;

export var Widgets = {
	CallLink: styled.a`
		display: flex;
		align-self: center;
		justify-self: center;

		font-size: 4vw;
		text-decoration: none;
		color: ${ theme.light.fontColorHighlight };
	`,
	CloseButton: styled.button`
		float: right;
		margin-right: 24px;
        margin-bottom: 24px !important;;
		padding: 10px 16px;

		cursor: pointer;
		letter-spacing: 0.75px;
		font-size: 16px;
	`
};