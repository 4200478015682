import styled from 'vue-styled-components';
import Theme from '@/system/styles/theme';

//import PlayCallout from '@/assets/play-callout.png';

export var Widgets = {
    StarField: styled.div`
        transition: 0.33s ease height;
        position: relative;
        left: -10vw; // Ignore page margins
        display: block;
        width: 100vw; 
        height: 100vh; // height: calc(100vh - 110px);
        background: black;
        overflow: hidden; // crop the canvas background if it bugs out

        &.fullscreen {
            height: 100vh;
        }
    `,
    DemoReelClickArea: styled.div`
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        position: absolute; // Needed (overlays canvas starfield)
        left: calc(50% - 33vw / 2);
        /* left: 33vw; */
        top: 0px;
        // position: absolute;
        height: 100%;
        width: 33vw;
        cursor: pointer !important;
        pointer-events: none; // Default

        &.enabled {
            pointer-events: all;

            & > #arrow-prompt {
                opacity: 1;
            }
        }

        &:hover {
            // & > div {
            //     opacity: 1;
            // }

            // & > #arrow-prompt {
            //     opacity: 0 !important; // Hide this so we can focus on the demo reel
            // }

            // Disabled for now
            // & ~ p {
             //   opacity: 0 !important;
            // }
            // & > #sirius-major {
            //     filter: brightness(0.5);
            // }
        }
    `,
    Major: styled.img`
        @keyframes FadeInMajor {
            0% {
                opacity: 0;
            }

            60% {
                opacity: 1;
                //pointer-events: none;
            }

            100% {
                opacity: 0;
                //pointer-events: all;
            }
        }
        
        position: absolute;
        pointer-events: none !important;
        user-select: none;
        //cursor: pointer;
        animation: FadeInMajor 7s forwards;
        animation-delay: 4s;
        /* animation-delay: 0s; */
            /* position: absolute;
            left: calc(50vw - 75px);
            top: calc(50vh - 175px); */
        height: 200px;
        width: 200px;
        opacity: 0; // Start invisible

        &:hover {
            //filter: hue-rotate(250deg);
        }
    `,
    MajorVFXLayer: styled.img`
        @keyframes FadeInMajorVFX {
            0% {
                opacity: 0;
            }

            30% {
                opacity: 0.7;
                //pointer-events: none;
            }

            70% {
                opacity: 0.5;
            }

            100% {
                opacity: 0;
                //pointer-events: all;
            }
        }
        
        position: absolute;
        pointer-events: none !important;
        user-select: none;
        //cursor: pointer;
        animation: FadeInMajorVFX 8s ease-in-out;
        animation-delay: 4s;
        /* animation-delay: 0s; */
            /* position: absolute;
            left: calc(50vw - 75px);
            top: calc(50vh - 175px); */
        height: 200px;
        width: 200px;
        opacity: 0; // Start invisible

        &:hover {
            //filter: hue-rotate(250deg);
        }
    `,
    PlayCallout: styled.div`
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        color: ${Theme.light.fontColorHighlight};
        transition: 1s ease all;
        background-color: white;
        border: 2px solid ${Theme.light.fontColorHighlight};
        border-radius: 100%;
        opacity: 0; // Starting
        height: 100px;
        width: 120px;
        position: absolute;
        top: 10%;
        left: calc(50% - 60px);

        &.active {
            opacity: 1;
        }
    `,
    PlayCalloutCloud2: styled.div`
        opacity: 0; // Starting
        transition: 0.5s ease all;
        // animation-delay: 1s;
        position: absolute;
        width: 40px;
        height: 40px;
        background: white;
        border: 2px solid ${Theme.light.fontColorHighlight};
        border-radius: 100%;
        top: calc(10% + 95px);
        left: calc(50% + 40px);

        &.active {
            opacity: 1;
        }
    `,
    PlayCalloutCloud1: styled.div`
        opacity: 0; // Starting
        transition: 0.25s ease all;
        //animation-delay: 0.5s;
        position: absolute;
        width: 20px;
        height: 20px;
        background: white;
        border: 2px solid ${Theme.light.fontColorHighlight};
        border-radius: 100%;
        top: calc(10% + 150px);
        left: calc(50% + 50px);

        &.active {
            opacity: 1;
        }
    `,
    Star: styled.div`
        @keyframes FadeInStar {
            0% {  opacity: 0; }
            100% { opacity: 0.5; }
        }
        
		animation: 3s FadeInStar linear;
		position: absolute;
		width: 1px;
		height: 1px;
		opacity: 0.5;
		border-radius: 100%;
		background-color: rgb(200,200,255);
		transition: all ease 4s;

        // Initial state
		&.bright {
			opacity: 1;
		}

        // Initial state
		&.dim {
			opacity: 0.25;
		}

        // Dynamic state
        &.fading {
            transition: all ease 0.5s;
            opacity: 0;
        }
    `,
    LeftHeroText: styled.p`
        @keyframes FadeInLeftHeroText {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 1;
            }    



            100% {
                opacity: 0;
            }
        } 

        overflow: hidden;
        white-space: nowrap;
        user-select: none;
        pointer-events: none;
		animation: 10s FadeInLeftHeroText forwards;
        animation-delay: 1s;
        position: absolute;
        font-size: 50px !important; // 32px;
        left: 0px; //calc(50vw + 150px);
        top: 0px; // calc(25vh - 16px);
        opacity: 0;
        color: white !important;
        width: 33%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 8px !important;
        line-height: 80px !important;
        font-family: 'quicksand' !important;
        transform: scaleY(0.95);
        //max-width: 30vw;
    `,
    RightHeroText: styled.p`
        @keyframes FadeInRightHeroText {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        } 

        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
        user-select: none;
        transition: 1s ease all;
		animation: 10s FadeInRightHeroText forwards;
        animation-delay: 5.50s;
        position: absolute;
        font-size: 75px !important; //72px !important;
        //font-weight: bold !important;
        right: 0px; //calc(50vw + 150px);
        // DON'T set top, we relocate this on mobile using bottom FENCE: top: 0px; // calc(25vh - 16px);
        bottom: 0px;
        opacity: 0;
        color: white !important;
        //max-width: calc(50vw - 75px);
        width: 33%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 8px !important;
        line-height: 100px !important;
        font-family: 'quicksand' !important;
        transform: scaleY(0.95);
    `
    // Chevron: styled.a`
    //     @keyframes Hover {
    //         0% {
    //             margin-bottom: 0px;
    //         }

    //         25% {
    //             margin-bottom: -12px;
    //         }

    //         50% {
    //             margin-bottom: 0px;
    //         }

    //         75% {
    //             margin-bottom: 12px;
    //         }

    //         100% {
    //             margin-bottom: 0px;
    //         }
    //     }

    //     animation: 1s Hover linear infinite;
    //     transition: 1s ease all;
    //     position: absolute;
    //     bottom: 36px;
    //     width: 0; 
    //     height: 0;
    //     margin-left: 10px;
    //     border-left: 20px solid transparent;
    //     border-right: 20px solid transparent;
    //     border-top: 20px solid white;
    //     opacity: 0; // starting
    //     filter: drop-shadow(0 0 3px black);
    // `
};