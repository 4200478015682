import styled from 'vue-styled-components';
import { css } from 'vue-styled-components';

import theme from '@/system/styles/theme';

let height = 50; // px FENCE: we do this staticallly because of max-height needs vs vh calculations
let endcapSize = height; // px, this is calaulated from a 45 degree slope skew such that it depends only on height
let contentOffsetX = 68; // px, this is the form of sqrt(a^2 + b^2) = ~70.1 but static to fix rounding errors and look pretty
let defaultSlopeLeft = 45; // deg
let defaultSlopeRight = -45; // deg

// This is for the creation of shadows specifically
let shadowSpread = 4; // px
let shadowSize = 0; // px
let shadowOffsetY = 0; // px;
let shadowOffsetX = 0; // px
let shadowColor = 'black';

let shadowMarginY = shadowSize * 2 + shadowSpread; // px -- May want to merge this with endcapSize or something

let half = css`
    position: absolute;
    width: 50%;
    height: 100%;

    overflow: hidden;
    pointer-events: none;
`;

// do NOT use absolute for outermost positioning or iOS safari barfs
export var Widgets = {
    BevelBar: styled.div`
        transition: 0.25s ease height !important; // HACKHACK
        transform-origin: top;
        
        position: fixed;
		//width: 40vw;
        width: 300px;
		height: 50px;
        
        pointer-events: none;
        cursor: pointer;

        &.collapsed {
            height: 50px !important; // HACKHACK 
        }

        // Auto-highlight (change this later if you need a new bar format)
        &.bar-hover > .content-area {
            color: ${theme.light.fontColorHighlight};
        }

        &.ui-active {
            & > .ui-toggle {
                display: none;
            }
                
            & > .content-area {
                opacity: 1;
            }
        }

        &.ui-hidden {
            & > .ui-toggle {
                display: block;
            }

            & > .content-area {
                opacity: 0;
            }
        }
    `,
    BlockingOverlay: styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 9999;

        background: transparent;
        pointer-events: all;
        cursor: default;
    `,
    ContentArea: styled('div', { align: String })`
        transition: color 0s, text-shadow 0.5s, opacity 1s;

        z-index: 100;
        position: absolute;
		display: flex;
        left: ${contentOffsetX}px;
        width: calc(100% - ${contentOffsetX*2}px);
        height: calc(100% - ${shadowMarginY}px);
        ${(props) => (props.align === 'bottom' ? 'bottom: 0px;' : '')}

        white-space: nowrap;
        overflow: hidden;
        pointer-events: none;
        user-select: none;
        align-items: center;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
        justify-content: center !important;

        // Give the inline icons padding
        & > svg {
            margin-right: 10px;
        }
    `,
    LeftHalf: styled.div`
        ${half};

        left: 1px;
    `,
    RightHalf: styled.div`
        ${half};

        right: 0px;
    `,
    LeftBackground: styled('div', { align: String, slope: Number, slopeLeft: Number })`
        transform: skewX(${(props) => props.slope || props.slopeLeft || defaultSlopeLeft}deg);
    
        position: absolute;
        left: ${endcapSize}px;
        width: 200%;
        height: calc(100% - ${shadowMarginY}px);
        ${(props) => (props.align === 'bottom' ? 'bottom: 0px;' : '')}

        pointer-events: all;
        background-color: white;
        box-shadow: ${shadowOffsetX}px ${shadowOffsetY}px ${shadowSize}px ${shadowSpread}px ${shadowColor};
    `,
    RightBackground: styled('div', { align: String, slope: Number, slopeRight: Number })`
        transform: skewX(${(props) => props.slope * -1 || props.slopeRight || defaultSlopeRight}deg);

        width: 200%;
        float: right;
        height: calc(100% - ${shadowMarginY}px);
        right: ${endcapSize}px;
        position: absolute;
        ${(props) => (props.align === 'bottom' ? 'bottom: 0px;' : '')}
    
        pointer-events: all;
        background-color: white;
        box-shadow: ${shadowOffsetX}px ${shadowOffsetY}px ${shadowSize}px ${shadowSpread}px ${shadowColor};
    `
}