<script>
	import { Widgets } from './Modal.vh';

	export default {
		components: {
			ModalMask: Widgets.ModalMask,
			ModalWrapper: Widgets.ModalWrapper,
			ModalContainer: Widgets.ModalContainer,
            ModalCloseButton: Widgets.ModalCloseButton,
			ModalHeader: Widgets.ModalHeader,
			ModalBody: Widgets.ModalBody,
			ModalFooter: Widgets.ModalFooter
		}
	}

</script>

<style lang="scss" scoped>

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

</style>

<template>
	<transition name="modal" >
		<ModalMask class="modal-mask" v-on:click.self="$store.dispatch('dismissAllModals')">
			<ModalWrapper class="modal-wrapper">
				<ModalContainer class="modal-container">

					<ModalHeader class="modal-header">
                        <ModalCloseButton @click="$store.dispatch('dismissAllModals')">
                            <FontAwesome icon="times" />   
                        </ModalCloseButton>

						<slot name="header">
						</slot>
					</ModalHeader>

					<ModalBody class="modal-body">
						<slot name="body">
						</slot>
					</ModalBody>

					<ModalFooter class="modal-footer">
						<slot name="footer">
						</slot>
					</ModalFooter>

				</ModalContainer>
			</ModalWrapper>
		</ModalMask>
	</transition>

</template>