import styled from 'vue-styled-components';

import theme from '@/system/styles/theme.js';

import Modal from './abstract/Modal.vue';

export var StyledModal = styled(Modal)`
    & > * > .modal-container {
        width: 700px !important;
        border-radius: 48px;

        & > .modal-header {
            border-radius: 40px 40px 0px 0px;
        }
    }
`;

export var Widgets = {
    ContactButton: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        border: 12px solid #191919;
        border-radius: 36px;
        text-align: center;
        line-height: 24px;
        margin-left: 24px;
        margin-right: 24px;
        padding: 12px;
        cursor: pointer;

        &:hover {
            border-color: lightgray;

            & > * {
                color: rgb(0,0,238) !important;
            }
        }
    `,
    // ContactButton: styled.div`
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     width: 125px;
    //     height: 100px;
    //     border: 1px solid transparent;
    //     border-radius: 6px;
    //     text-align: center;
    //     line-height: 24px;
    //     margin-left: 12px;
    //     margin-right: 12px;
    //     padding: 12px;
    //     cursor: pointer;

    //     &:hover {
    //         border-color: lightgray;

    //         & > * {
    //             color: rgb(0,0,238) !important;
    //         }
    //     }
    // `,
    // PhoneContactButton: styled(EmailContactButton)`
    // `,
    // LinkedInContactButton: styled(EmailContactButton)`
    // `,
	// CallLink: styled.a`
	// 	display: flex;
	// 	align-self: center;
	// 	justify-self: center;

	// 	font-size: 4vw;
	// 	text-decoration: none;
	// 	color: ${ theme.light.fontColorHighlight };
	// `,
	CloseButton: styled.button`
		float: right;
		margin-right: 24px;
		padding: 10px 16px;

		cursor: pointer;
		letter-spacing: 1.5px;
		font-size: 16px;
	`
};