<script>

    import { Widgets } from './StrategyTab.vh';

	export default {
		name: 'strategy-tab',
        components: {
            StrategyTab: Widgets.StrategyTab
        }
	}
    
</script>

<template>

	<StrategyTab id="strategy-tab" :style="!$store.state.roiTrayVisible && $store.state.topic === 'topic-strategy' ? 'height: 65px !important' : ''"
		:class="$store.state.topic === 'topic-strategy' ? 'active' : 'idle'"
		@bevel-bar-clicked="$store.commit('setDesiredTopic', 'topic-strategy')">

        <FontAwesome icon="cogs" />Strategy
	</StrategyTab>

</template>