<script>

	import { Widgets } from './Logo.vh';
	import LogoImageSrc from '@/assets/k9-logo-basic.svg';
	
	export default {
        name: 'logo',
		components: {
			Logo: Widgets.Logo,
			LogoImage: Widgets.LogoImage,
			SubtitleArea: Widgets.SubtitleArea
		},
		data: () => ({
			LogoImageSrc
		})
	}

</script>

<template>

    <Logo>
        <LogoImage :src="LogoImageSrc" />
        <SubtitleArea>
			<span class="italic text">Very </span>good.
		</SubtitleArea>
    </Logo>
    
</template>