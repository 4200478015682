import styled from 'vue-styled-components';
import DepthMap from '@/system/styles/depth-map';

export var BackdropScreen = styled.div`
    z-index: ${DepthMap.SHADES};
    transition: background-color 1s;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    cursor: pointer;
    background-color: rgba(0,0,0,0.5);
`;