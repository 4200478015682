<script>
	import store from '@/system/store';

	import '../system/styles/reset.scss';
	import '../system/styles/fonts.scss';
	import '../system/styles/text.scss';
	import '../system/styles/animation.scss';
	import '../system/styles/screens.scss';

	import StrategyTab   from '../components/tabs/StrategyTab.vue';
	import DesignTab     from '../components/tabs/DesignTab.vue';
	import TechTab       from '../components/tabs/TechTab.vue';
	import FAQTab        from '../components/tabs/FAQTab.vue';
	import AboutTab      from '../components/tabs/AboutTab.vue';
	import ContactTab    from '../components/tabs/ContactTab.vue';
	import TheaterScreen from '../components/TheaterScreen.vue';
	import ContactModal from '../components/modals/Contact.vue';
    import AboutModal from '../components/modals/About.vue';
    import PupBoy from '../components/mobile/PupBoy.vue';
    import EmailButton from '@/components/mobile/EmailButton.vue';
    import PhoneButton from '@/components/mobile/PhoneButton.vue';
	import ROITray from '../components/ROITray.vue';
    import BackdropScreen from '@/components/BackdropScreen.vue';
	//import ROIMenu from '../components/menus/ROIMenu.vue';
	//import BusinessServicesMenu from '../components/menus/BusinessServicesMenu.vue';
	//import ContactMenu from '../components/menus/ContactMenu.vue';
	import DynamicBackground from '../components/DynamicBackground.vue';
	import { Widgets, focusTopic } from './App.vh';

	export default {
		name: 'app',
		components: {
			StrategyTab,
			DesignTab,
			TechTab,
			FAQTab,
			AboutTab,
			ContactTab,
			TheaterScreen,
			ContactModal,
            AboutModal,
            PupBoy,
            EmailButton,
            PhoneButton,
			ROITray,
            BackdropScreen,
			MenuLayer: Widgets.MenuLayer,
			DynamicBackground,
			App: Widgets.App,
			RouterContainer: Widgets.RouterContainer
		},
		computed: {
			volume: () => store.state.volume,
			desiredTopic: () => store.state.desiredTopic
		},
		watch: {
			// volume: (oldVolume, volume) => updateVolume(oldVolume, volume, audio),
			desiredTopic: (desiredTopic) => focusTopic(desiredTopic)
		},
        created() {
            if (this.$route.params?.topic) { // Base will come in undefined in "/"" or "" case
                //console.log("App created", this.$router);
                //store.state.initialRoute = this.$route.params.topic;
                let initialRoute = this.$route.params.topic;
                //console.log("INITIALLY WAS")
                this.$router.replace('/'); // We always start here, then animate
                //this.$router.push(initialRoute);
                // setTimeout(() => {
                //     this.$router.push(oldPath);
                // }, 2000);

                //setTimeout(() => {
                
                // TODO NEXT: 
                // Instead of this, just force the target card to flip, then it will do routing
                //this.$router.push(initialRoute);
               
               store.commit('saveInitialRoute', initialRoute);
               
               //}, 1);
            }
        }//,
        // mounted() {
        //     if (initialRoute) {
        //         let desiredRoute = initialRoute.toString();
        //         initialRoute = null;
        //         setTimeout(() => {
        //             this.$router.push(desiredRoute);
        //         }, 1000);
        //     }
        // }
	}

</script>

<style lang="scss" scoped>
    .esc-text:hover {
    }
</style>

<template>

	<App>
		<ContactModal v-if="$store.state.activeModals.contact" />
        <AboutModal v-if="$store.state.activeModals.about" />

        <div v-show="$store.state.readingMode" class="esc-text"
            :style="$store.state.roiTrayVisible && 'font-size: 22px; top: unset; bottom: 100px'" 
            style="pointer-events: none; color: #EEE; font-size: 16px; z-index: 9999; position: fixed; left: calc(50% - 48px); top: 12px">
            esc to exit
        </div>

		<ROITray />

        <BackdropScreen
            v-if="$store.state.roiTrayVisible"
            @click="$store.dispatch('toggleROITray')" />

		<MenuLayer>
			<StrategyTab  :class="$store.state.activeTabs.strategy  ? 'ui-active' : 'ui-hidden'" />
			<DesignTab    :class="$store.state.activeTabs.design    ? 'ui-active' : 'ui-hidden'" />
			<TechTab      :class="$store.state.activeTabs.tech      ? 'ui-active' : 'ui-hidden'" />
			<FAQTab       :class="$store.state.activeTabs.faq       ? 'ui-active' : 'ui-hidden'" />
			<AboutTab     :class="$store.state.activeTabs.about     ? 'ui-active' : 'ui-hidden'" />
			<ContactTab   :class="$store.state.activeTabs.contact   ? 'ui-active' : 'ui-hidden'" />

            <PupBoy />
            <EmailButton />
            <PhoneButton />
		</MenuLayer>

		<TheaterScreen style="display: none"/>

		<RouterContainer
			id="router-container"
			@scroll="$store.commit('routerScrollEvent', $event.target)"
			:class="$store.state.themeMode">
			<transition name="fade">
				<router-view class="router-content-area" />
			</transition>
		</RouterContainer>
		
        <DynamicBackground />
	</App>

</template>
