<script>
	import { StyledModal, Widgets } from './Contact.vh';

	export default {
		name: 'modal-contact',
		components: {
			StyledModal,
			//CallLink: Widgets.CallLink,
			// CloseButton: Widgets.CloseButton,
            ContactButton: Widgets.ContactButton,
            // PhoneContactButton: Widgets.PhoneContactButton,
            // LinkedInContactButton: Widgets.LinkedInContactButton
		},
        data: () => ({
            phoneNumberVisible: false
        }),
        methods: {
            composeEmail(division, subject) {
                window.open(`mailto:${division}@k9interactive.com?subject=${subject}`);
            },
            togglePhoneNumberVisibility() {
                this.phoneNumberVisible = !this.phoneNumberVisible;
            },
            openLinkedInPage() {
                window.open('https://www.linkedin.com/company/k9interactive');
            },
            startPhoneCall() {
                window.open('tel:+2027501699', '_self');
            }
        }
	}
</script>


<style lang="scss" scoped>
	.call-link {
		text-decoration: none;
	}
    #main-contact-buttons, #aux-contact-buttons {
        text-align: center;
        width: 100%;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 60px;
        flex-wrap: wrap;
        overflow: auto !important;
    }
    .email-icon {
        font-size: 28px;
    }

    // @HACKHACK
    .modal-container {
        width: 50vw !important;
    }
</style>

<template>
	<StyledModal>

		<template v-slot:header>
			Please Select a Contact Method
		</template>

		<template v-slot:body>
            <div id="main-contact-buttons">
                <ContactButton @click="composeEmail('strategy', 'Business Strategy Inquiry for <Your Awesome Company>')">
                    <FontAwesome class="email-icon" style="font-size: 64px" :icon="['fas', 'cogs']" />
                    <p style="position: absolute; font-weight: bold;  margin-bottom: 225px">Strategic Planning</p>
                </ContactButton>
                <ContactButton @click="composeEmail('design', 'Customer Experience Inquiry for <Your Awesome Company>')">
                    <FontAwesome class="email-icon" style="font-size: 64px" :icon="['fas', 'drafting-compass']" />
                    <p style="position: absolute; font-weight: bold;  margin-bottom: 225px">Design Services</p>
                </ContactButton>
                <ContactButton @click="composeEmail('engineering', 'Software Architecture Inquiry for <Your Awesome Company>')">
                    <FontAwesome class="email-icon" style="font-size: 64px" :icon="['fas', 'code']" />
                    <p style="position: absolute; font-weight: bold;  margin-bottom: 225px">UI Architecture</p>
                </ContactButton>
            </div>
            <div id="aux-contact-buttons" style="margin-top: 32px">
                <ContactButton @click="togglePhoneNumberVisibility" :style="phoneNumberVisible && 'border-color: lime'">
                    <FontAwesome v-if="!phoneNumberVisible" class="email-icon" style="font-size: 64px" :icon="['fas', 'phone']" />
                    <FontAwesome v-if="phoneNumberVisible" @click="startPhoneCall()" class="email-icon"  style="font-size: 80px; transform: rotateZ(50deg)" :icon="['fa', 'phone-volume']" />
                    <p v-if="!phoneNumberVisible" style="font-weight: bold; position: absolute; margin-bottom: -225px">Phone</p>
                    <p v-if="phoneNumberVisible" style="font-weight: bold; position: absolute; margin-bottom: -225px">(202) 750 - 1699</p>
                    <p v-if="phoneNumberVisible" @click="startPhoneCall()" style="font-weight: bold; position: absolute; margin-bottom: -80px">CALL</p>
                </ContactButton>
                <ContactButton @click="openLinkedInPage()">
                    <FontAwesome class="email-icon" style="font-size: 70px" :icon="['fab', 'linkedin']" />
                    <p style="position: absolute; font-weight: bold;  margin-bottom: -225px">LinkedIn</p>
                </ContactButton>
                <ContactButton @click="composeEmail('partners', 'Partnership Inquiry from <Your Awesome Company>')">
                    <FontAwesome class="email-icon" style="font-size: 64px" :icon="['fas', 'handshake']" />
                    <p style="position: absolute; font-weight: bold;  margin-bottom: -225px">Corporate Partners</p>
                </ContactButton>
            </div>
		</template>

		<template v-slot:footer>
			<!-- <CloseButton @click="$store.dispatch('dismissAllModals')">
				CLOSE
			</CloseButton> -->
		</template>

	</StyledModal>
</template>