export default {
    BACKGROUND: 1000,
    ROUTING: 2000,
    DEMO_REEL: 2250,
    FOCUSED_CONTENT: 2500,
    SHADES: 2600,
    MODALS: 2750,
    TRAYS: 2800,
    MENUS: 3000,
    //MENU_CONTACT: 4000
    TAB_STRATEGY: 3030,
    TAB_DESIGN: 3020,
    TAB_TECH: 3010,
    TAB_FAQ: 4010,
    TAB_ABOUT: 4020,
    TAB_CONTACT: 4030,
    PUP_BOY: 9001
};