<script>
	import store from '@/system/store';

	import { Widgets } from './Welcome.vh';
	import Logo from '@/components/Logo.vue';
	import Topic from '@/components/Topic.vue';
	import SpaceHero from '@/components/SpaceHero.vue';

    import Card from '@/components/Card.vue';
    import MiniCard from '@/components/MiniCard.vue';
    import CTAButton from '@/components/CTAButton.vue';

	import TimingMap from '@/system/styles/timing-map';

	import StrategyServiceImage from '@/assets/nose.jpg';
	import UXServiceImage from '@/assets/sci-fi-ui.jpg';
	import UIServiceImage from '@/assets/team-table.jpg';

	import CurledUpDoggo from '@/assets/curled-up-doggo.png';

	let currentYear = new Date().getFullYear(); // for copyright byline auto-update in footer

	export default {
        name: 'route-welcome',
		components: {
			Logo,
			Topic,
			SpaceHero,

            Card,
            MiniCard,
            CTAButton,

			Home: Widgets.Home,
			Footer: Widgets.Footer,
			CopyrightNotice: Widgets.CopyrightNotice,
			Disclaimer: Widgets.Disclaimer,
			Emblem: Widgets.Emblem,

            DuplexSection: Widgets.DuplexSection,
            OverfoldImage: Widgets.OverfoldImage,
            BioMinderImage: Widgets.BioMinderImage,
            DimeQuestImage: Widgets.DimeQuestImage
		},
		data: () => ({
			StrategyServiceImage,
			UXServiceImage,
			UIServiceImage,
			CurledUpDoggo,
			currentYear
		}),
        methods: {
            // console.log(this.scrollContext);
            strategyClick: function() {
                console.warn("STRATEGY CARD CLICKED");
            },
            hi: function() {
                console.log("HIIIIIII");
            }
        },
		mounted() {
			setTimeout(() => { store.commit('toggleTabInteraction', 'tech') }, TimingMap.TAB_TECH * 1000);
			setTimeout(() => { store.commit('toggleTabInteraction', 'faq') }, TimingMap.TAB_FAQ * 1000);
			setTimeout(() => { store.commit('toggleTabInteraction', 'design') }, TimingMap.TAB_DESIGN * 1000);
			setTimeout(() => { store.commit('toggleTabInteraction', 'about') }, TimingMap.TAB_ABOUT * 1000);
			setTimeout(() => { store.commit('toggleTabInteraction', 'strategy') }, TimingMap.TAB_STRATEGY * 1000);
			setTimeout(() => { store.commit('toggleTabInteraction', 'contact') }, TimingMap.TAB_CONTACT * 1000);

            let humanReadableTopic = this.$route.params.topic;

            if (! humanReadableTopic) {
                return;
            }

            let desiredTopic =  (humanReadableTopic === 'strategy') ? 'topic-strategy' :
                                (humanReadableTopic === 'design')   ? 'topic-ux' :
                                (humanReadableTopic === 'tech')     ? 'topic-ui' :
                                'ERROR_INVALID_URL_PARAMETER_FOR_TOPIC';

            let targetFlipCard =    (desiredTopic === 'topic-strategy') ? 'card-strategy' :
                                    (desiredTopic === 'topic-ux')       ? 'card-ux' :
                                    (desiredTopic === 'topic-ui')       ? 'card-ui' :
                                    'ERROR_INVALID_CARD_REF';

            if (desiredTopic !== 'ERROR_INVALID_URL_PARAMETER_FOR_TOPIC') {
                if (targetFlipCard !== 'ERROR_INVALID_CARD_REF') {
                    this.$refs[targetFlipCard].flip();
                }
            }
		}
	}

</script>


<style lang="scss">
// HACKHACK
    .card-back-body {
        margin: 5vw;
        color: rgb(200,200,200) !important;

        & > p {
            color: rgb(200,200,200) !important;
            margin-bottom: 5vh;
        }
    }

    h1.card.back.text {
        font-size: 5vw;
        margin-bottom: 0px;
    }

    // FENCE: Styled-Components was breaking their member functions, now we can flip() them
    #card-strategy {
        float: right;
        width: 40vw;
        height: 26vw;
        margin-left: 6vw;

    }
    #card-ux {
        float: left;
        width: 35vw;
        height: 32vw;
        margin-right: 6vw;

    }
    #card-ui {
        float: right;
        margin-left: 6vw;
        width: 36vw;
        height: 36vw;

    }
</style>
<template>
	<Home class="home">
		<SpaceHero id="space-hero" />

		<Topic id="topic-introduction">
			<Logo />
			
			<br />
			<br />
			<h1 id="title-introduction">Welcome to K9 Interactive.</h1>
			<p>K9 is the design house that speaks to developers.  We provide an ideal mix of experience, communication process, and tools to collaborate comprehensively in a way that no one else can.</p>
			<p>We always push the envelope of what is aesthetic, break down barriers of friction, and deliver custom solutions - but we're also painfully aware of the challenges developers face in the sticky world of User Interface Programming.</p>
			<p>Our unusually technical expertise, combined with business acumen from early-phase startups to established projects at the $10MM level and beyond eliminates whole classes of communication breakdowns, and fosters a culture of simplicity, teamwork, and excellence wherever we go.</p>
			<br />
		</Topic>

		<Topic id="topic-strategy" >
			<h1 id="title-strategy">A Nose For Success</h1>		
			<Card id="card-strategy" ref="card-strategy" :image="StrategyServiceImage" class="strat">
                <OverfoldImage alt="Overfold" />
                <h1 class="card back text">Overfold</h1>
                <div class="card-back-body">
                    <p>The client had approached us to solve business and design challenges that they felt were overwhelming to their team, and to innovate in the space, while also providing all of the UI programming architecture that would be, "So easy, an intern can debug it."</p>
                    <DuplexSection class="right">
                        <MiniCard frontImage="@/assets/overfold-card-wire.png" backImage="@/assets/overfold-card.png" />
                        <p>They needed a way to understand roles such as <i>villains</i> and <i>blockers</i> and not only what they were on their own, but to be able to keep track of relationships between the data and to most of all, be provided a view "over the fold" of the battle.</p>
                    </DuplexSection>

                    <DuplexSection>
                        <MiniCard frontImage="@/assets/overfold-graph-wire.png" backImage="@/assets/overfold-graph.png" />
                        <p>These insights took us through many other stages of highly-nuanced, sometimes buggy, and always detailed <i>"quest pursuit"</i> internal tooling.</p>
                    </DuplexSection>

                    <DuplexSection class="right">
                        <MiniCard frontImage="@/assets/overfold-logo-wire.png" backImage="@/assets/overfold-logo.png" />
                        <p>They viewed all of this through the visual metaphor of "paperwork" and so the logo design and graphic theme would naturally follow suit.</p>
                    </DuplexSection>

                    <DuplexSection>
                        <MiniCard frontImage="@/assets/overfold-card-2-wire.png" backImage="@/assets/overfold-card-2.png" />
                        <p>The essence of their difficulties turned out to be a taxonomy system with <i>so</i> many tags, and <i>so</i> many possible roles, and a need to assign these roles to <i>so</i> many entities that the previously attempted design incarnations proved daunting or outright unusable for their analyst team.</p>
                    </DuplexSection>

                    <p>The results and response were overwhelming and this project served to reinforce our process which is underpinned by a fierce recognition that truly great products and systems come from 90% wise process and only about 10% visual design.</p>
                    <p>This case was about good looks, but more than that, it was about storytelling, clarity, defect-ridden associations, and a deep understanding of their unique needs.</p>
                    <p>If that sounds like something you could use too, we're <a class="link text dark" href="mailto:production@k9interactive.com?subject=User Experience Inquiry for <Your Awesome Company>">always here to listen to your exciting story</a> in all of its careful nuance.</p>
                </div>
			</Card>
			<p>Excel with an ease you never thought possible. Everybody wants to give you a <i>piece</i> of the answer.  <i>Get it all at once.</i></p>
            <CTAButton @click="$refs['card-strategy'].flip()">EXPLORE</CTAButton>
        </Topic>

		<Topic id="topic-ux">
			<h1 id="title-ux">Best In Show<br />Best In Business</h1>
			<Card id="card-ux" align="left" ref="card-ux" :image="UXServiceImage">

                <BioMinderImage alt="BioMinder" />
                <h1 class="card back text">BioMinder</h1>
                <div class="card-back-body">
                    <p>We were brought in to oversee a department in User Experience and User Interface. The most significant of which was the BioMinder project, comprising about half ($10MM) of their total assets.</p>
                    
                    <DuplexSection>
                        <MiniCard frontImage="@/assets/biominder-keymap-wire.png" backImage="@/assets/biominder-keymap.png" />
                        <p>One of the first things that we did was to sit down with a small cohort of stakeholders and <i>map out</i> exactly what their <i>pain points</i> and favorite features were.  They quickly settled on hotkeys, so much of the application's design flowed from that key requirement.</p>
                    </DuplexSection>

                    <DuplexSection class="right">
                        <MiniCard frontImage="@/assets/biominder-mouse-wire.png" backImage="@/assets/biominder-mouse.png" />
                        <p>The mouse handler in particular was abstracted away into its own subsystem to handle inputs, the task of reimagining the browser experience for a very specific set of requests felt a lot like implementing Figma or Photoshop, and we ran across a lot of similar concerns.</p>
                    </DuplexSection>

                    <DuplexSection>
                        <MiniCard frontImage="@/assets/biominder-tray-wire.png" backImage="@/assets/biominder-tray.png" />
                        <p>One such concern was how the application would respond to tool selection state.  We followed simple best practices from other tools to <i>morph the right-side menu as different tools were selected</i>, providing context-aware data about the present state of the viewport to the user.</p>
                    </DuplexSection>

                    <DuplexSection class="right">
                        <MiniCard frontImage="@/assets/biominder-timeline-wire.png" backImage="@/assets/biominder-timeline.png" />
                        <p>Among the most important innovations during our time there was the organization of a large repository of scientific data into manageable chunks.  Originally, they had not thought to organize with primary concern toward <i>time</i>.  With enough research it was clear that this was the right way forward.</p>
                    </DuplexSection>

                    <p>We were told that upon our departure, our contributions had grown to comprise 30% of the total worth of the (over $10MM) project, and had caused it to branch out to <i>triple</i> the clients they were previously serving, with little effort on the part of any of their sales staff.  If that sounds like something you could use, give us a chance to dream with you and then go <a class="link text dark" href="mailto:design@k9interactive.com?subject=Digital Experience Inquiry for <Your Awesome Company>">turn your goals into digital reality</a>.</p>
                </div>
			</Card>
			<p>Blow your customers away with next-generation aesthetics and a bloodhound's tenacity for <i>frictionless experiences</i>.</p>
            <CTAButton @click="$refs['card-ux'].flip()" style="float:right">EXPLORE</CTAButton>
        </Topic>

		<Topic id="topic-ui">
			<h1 id="title-ui">Leading The Pack</h1>
			<Card id="card-ui" ref="card-ui" :image="UIServiceImage">
                <DimeQuestImage alt="DimeQuest" style="box-shadow: 0px 0px 64px 32px black" />
                <h1 class="card back text">DimeQuest</h1>
                <div class="card-back-body">

                    <DuplexSection class="right">
                        <MiniCard frontImage="@/assets/dimequest-map-wire.png" backImage="@/assets/dimequest-map.png" />
                        <p>DimeQuest was a gamification pilot project in the mobile space.  Users gained the ability to page through different options and pursue them, in the spirit of questing from the world of fantasy art and virtual world-building.</p>
                    </DuplexSection>

                    <DuplexSection>
                        <MiniCard frontImage="@/assets/dimequest-filters-wire.png" backImage="@/assets/dimequest-filters.png" />
                        <p>The "digital watch face" aesthetic that was requested required some graphic technique to turn shadows into edges, a lot like painting.  Here you can see this edgy style applied to a suite of map visibility filter buttons.</p>
                    </DuplexSection>

                    <DuplexSection class="right">
                        <MiniCard frontImage="@/assets/dimequest-progress-circle-wire.png" backImage="@/assets/dimequest-progress-circle.png" />
                        <p>The user would also need to follow a fairly complex set of real-world behaviors, so providing them a clear walkthrough process would be essential.  Each UI element was carefully tuned toward this goal.</p>
                    </DuplexSection>

                    <DuplexSection>
                        <MiniCard frontImage="@/assets/dimequest-compass-wire.png" backImage="@/assets/dimequest-compass.png" />
                        <p>The other question on their mind was usually "where" so we made sure to clearly communicate it even when they had entered the "tracker" mode.</p>
                    </DuplexSection>

                    <DuplexSection class="right">
                        <MiniCard frontImage="@/assets/dimequest-readout-wire.png" backImage="@/assets/dimequest-readout.png" />
                        <p>Giving users a reason to be excited about their progress was a core focus.  Our hope was to provide a reason to exercise, to try new things, and to grow as individuals.</p>
                    </DuplexSection>

                    <p>Finding visual consistency in a "watch face" application was a little novel, but we were very proud of it.  If you also have a unique project in mind that you think might take some skilled craftsmanship, and perhaps some similar questions about the technical side of the UI, please don't hesitate to briefly <a class="link text dark" href="mailto:engineering@k9interactive.com?subject=Engineering Support Inquiry for <Your Awesome Company>">start a conversation with us</a> about any and all things tech &amp; technique.</p>
                </div>

			</Card>
			<p>Relax and enjoy from day-one in a new freedom where you don't have to play translator because we all naturally <i>speak the same language</i>.</p>
            <CTAButton @click="$refs['card-ui'].flip()">EXPLORE</CTAButton>
        </Topic>

		<h1 style="text-align: center; width: 100%"><a style="font-weight: bold; font-size:42px; line-height: 50px; color: black; text-transform: unset !important" class="link text" href="mailto:explore@k9interactive.com?subject=Business Support Inquiry for <Your Awesome Company>">Let K9 be your guide.</a></h1>
		<br />
		<br />
		<br />
		<br />
		<br />
		<br />
		<br />
		<br />

		<Footer id="footer">

			<Disclaimer>
				Some project details may be simulated in order to protect proprietary, or other sensitive persons or information.
			</Disclaimer>
            
			<CopyrightNotice>
				Copyright © {{currentYear}} K9 Interactive LLC.<br />All rights reserved.<br /><br/>
			</CopyrightNotice>

			<Emblem :src="CurledUpDoggo"  />

		</Footer>

	</Home>
</template>
