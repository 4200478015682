// let electricBlue = 'rgb(0, 200, 255)';
// let deepBlue = 'rgb(0,123, 255)';

let electricBlue = 'rgb(0,100,255)';
let inkBlue = 'rgb(0,50,200)';
let silver = 'rgb(235, 235, 235)';
let smoke = 'rgb(250, 250, 250)';
let night = 'rgb(0, 0, 5)';

export default {
	swatches: {
		electricBlue,
		silver,
		smoke,
		night
	},
	light: {
		primaryColor: 'white',
		flipperColor: 'white',
		pageColor: smoke,
		fontColor: 'black',
		fontColorHighlight: inkBlue, // electricBlue,
		fontColorContent: 'rgb(20,20,20)',
		glyphColor: 'black',
		glyphColorHighlight: inkBlue, //electricBlue,
		shadowColor: 'rgba(0,0,0, 0.25)'
	},
	dark: {
		primaryColor: 'black',
		flipperColor: 'black',
		pageColor: 'black',
		fontColor: inkBlue, // electricBlue, //'rgb(50, 225, 50)',
		fontColorHighlight: 'lime',
		fontColorContent: silver,
		glyphColor: inkBlue, // electricBlue, // 'lime', //'rgb(50, 225, 50)',
		glyphColorHighlight: 'lime',
		glowColor: 'rgb(0, 100, 255)'
	}
}