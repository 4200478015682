<script>
	// import store from '@/system/store';

	import { Widgets } from './SpaceHero.vh';
    import Chevron from '@/components/Chevron.vue';
	import MajorImage from '@/assets/major-01-stars-only.svg'; //3-stars-glow-and-bars.svg';
	import MajorImageGlowSpots from '@/assets/major-02-stars-glow.svg'; //3-stars-glow-and-bars.svg';
	import MajorImageWithLines from '@/assets/major-03-stars-glow-and-bars.svg'; //3-stars-glow-and-bars.svg';
    import Space from '@/components/Space.vue';
    // import Logo3D from '@/components/Logo3D.vue';

	export default {
		name: 'space-hero',
		components: {
            DemoReelClickArea: Widgets.DemoReelClickArea,
			StarField: Widgets.StarField,
            Space,
            // Logo3D,
			Major: Widgets.Major,
            MajorVFXLayer: Widgets.MajorVFXLayer,
			LeftHeroText: Widgets.LeftHeroText,
			RightHeroText: Widgets.RightHeroText,
            Chevron
		},
		data: () => ({
			MajorImage,
            MajorImageGlowSpots,
            MajorImageWithLines,
            clickToNavigateEnabled: false
		}),
        mounted() {
            setTimeout(() => {
                this.clickToNavigateEnabled = true;
            }, 13000);
        }
	}

</script>

<style lang="scss" scoped>

    #arrow-prompt {
        @keyframes FadeInArrowPrompt {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        opacity: 0;
        animation: FadeInArrowPrompt 2s forwards;
        /* transition: 1s linear forwards; */
        animation-delay: 15s;
    }

</style>

<template>

    <StarField>
        <Space style="animation-delay: 2.75s" />

        <DemoReelClickArea id="sirius" :class="clickToNavigateEnabled && 'enabled'" @click="$store.commit('setDesiredTopic', 'topic-introduction')">
            <Major id="sirius-major" :src="MajorImage" />
            <MajorVFXLayer id="sirius-major-glow-spots" :src="MajorImageGlowSpots" style="animation-delay: 4.5s; animation-duration: 6s" />
            <MajorVFXLayer id="sirius-major-with-lines" :src="MajorImageWithLines" style="animation-delay: 5s; animation-duration: 3s; animation-timing-function: ease-in-out" />
            <Chevron style="bottom: 32px" id="arrow-prompt" />
        </DemoReelClickArea>

        <!-- Keep these after the click area or the sibling CSS selector breaks -->
		<LeftHeroText id="space-hero-text-first">The<br />Future<br />&nbsp;&nbsp;Is Good...</LeftHeroText>
		<RightHeroText id="space-hero-text-last">Very<br />&nbsp;Good.</RightHeroText>

		
	</StarField>

</template>