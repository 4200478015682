<script>	
	import { Widgets } from './TheaterScreen.vh';
	
	export default {
		name: 'theater-screen',
		components: {
			Screen: Widgets.Screen
		}
	}

</script>

<template>

	<Screen
		@click="$store.dispatch('theaterScreenClicked')"
		:class="$store.state.theaterMode ? 'active' : 'disabled'"
		:style="'z-index: ' + $store.state.theaterScreenDepth"
	/>

</template>