import styled from 'vue-styled-components';

export var Widgets = {
	ModalMask: styled.div`
		transition: opacity 0.3s ease;

		position: fixed;
		display: table;
		z-index: 9998;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.5);
        width: 100vw !important;
	`,
	ModalWrapper: styled.div`
		display: table-cell;

		pointer-events: none;
		vertical-align: middle;


	`,
	ModalContainer: styled.div`
		transition: all 0.3s ease;

		width: 75vw;
		height: 75vh;
		margin: 0px auto;

        border: 9px solid rgb(20, 20, 20);

		pointer-events: all;
		cursor: default;
		background-color: #fff;
		border-radius: 6px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
		font-family: Helvetica, Arial, sans-serif;
	`,
    ModalCloseButton: styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -32px;
        right: -32px;
        width: 48px;
        height: 48px;
        border-radius: 100px;
        background-color: rgb(240, 240, 240);
        border: 8px solid rgb(20, 20, 20);
        cursor: pointer;

        &:hover {
            background-color: white !important;
            border-color: rgb(240, 60, 60);
            color: rgb(240, 60, 60);
        }
    `,
	ModalHeader: styled.div`
        position: relative; // FENCE: Or the closebutton escapes
        font-family: Arial Black;
		padding: 20px 30px;
        letter-spacing: 0px;
        font-size: 26px !important;
        font-weight: 50;
		border-radius: 6px 6px 0px 0px;
		background-color: rgb(240, 240, 240);

		h3 {
			margin-top: 0;
			
			color: #42b983;
		}
	`,
	ModalBody: styled.div`
		margin: 24px;
		height: calc(100% - 160px);
        overflow: auto;
		//display: flex;

		//justify-content: center;
		//align-items: center;
	`,
	ModalFooter: styled.div`
		height: 32px;
		width: 100%;
	`
}