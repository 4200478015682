import styled from 'vue-styled-components';

export var FLIP_ANIMATION_DURATION = 500; // ms
export var BASE_TRANSITION = `all ${FLIP_ANIMATION_DURATION}ms`;

export var Widgets = {
    MiniCard: styled.div`
        transition: ${BASE_TRANSITION};
        transform-style: preserve-3d;
        perspective: 500px;

        width: 250px;
        height: 250px;
        border-radius: 4px;
        /* box-shadow: 0 10px 20px rgba(0,0,0, 0.50), 0 5px 10px rgba(0,0,0, 0.80); */
        // GOTCHA: This breaks backface-visibility because...?... filter: drop-shadow(0px 10px 20px black); */
        text-align: center;

        &.flipped {
            & > .frame {
                transform: rotateY(180deg);
            }
        }

        .overlay {
            transition: all 1s ease;
            opacity: 0;
        }

        &:hover .overlay {
            opacity: 1;
        }
    `,
    SpinFrame: styled.div`
        transition: ${BASE_TRANSITION};
        backface-visibility: visible;
        transform-style: preserve-3d;
        height: 100%;
        width: 100%;

    `,
    Front: styled('div', { image: String })`
        position: absolute;
        left: 0px;
        top: 0px;
        transition: ${BASE_TRANSITION};
        height: 100%;
        width: 100%;
        /* z-index: 100; */
        backface-visibility: hidden !important;
        transform-style: preserve-3d;

        background-image: url(${ (props) => props.image });
        background-size: contain; // TODO: Should this be : cover; ?
        background-repeat: no-repeat;
        background-position: center;

        /* z-index: 999; */
    `,
    Back: styled('div', { image: String })`
        position: absolute;
        top: 0px;
        left: 0px;
        transition: ${BASE_TRANSITION};
        transform-style: preserve-3d;  
        /* background: magenta; */
        width: 100%;
        height: 100%;
        /* z-index: 0; */
        backface-visibility: hidden !important;
        transform: rotateY(180deg);

        border-radius: 12px !important;
        background-image: url(${ (props) => props.image });
        background-size: contain; // TODO: Should this be : cover; ?
        background-repeat: no-repeat;
        background-position: center;
        /* background: magenta; */
    `
};