import styled from 'vue-styled-components';
import BevelBar from '@/components/BevelBar.vue';

export var PhoneButton = styled(BevelBar)`
	position: fixed;
    z-index: 9999;
    top: 0px;
    right: -100px;
    width: 200px !important;
    display: none;

    & > .content-area {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        margin-top: 8px;
        pointer-events: none !important;
    }
`;