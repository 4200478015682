import Vue from 'vue';
import Router from 'vue-router';

import Welcome from '@/system/places/Welcome.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() { // Just go to top every time for now, ignore (to, from, savedPosition)
		return { x: 0, y: 0 }
	},
	routes: [{
        path: '/',
        name: 'welcome',
        component: Welcome
	}, {
        path: '/:topic',
        name: 'welcome',
        component: Welcome
    }]
})
