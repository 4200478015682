import styled from 'vue-styled-components';

export var Widgets = {
    PageSection: styled.div`
		margin-bottom: 140px;
        float: left; //>
        width: 100%;

        & > h1 {
            line-height: 48px;
        }
    `
}