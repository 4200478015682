<script>

	import { Widgets } from './DynamicBackground.vh';
	
	export default {
        name: 'dynamic-background',
		components: {
			DynamicBackground: Widgets.DynamicBackground
		}
	}

</script>

<template>

	<DynamicBackground class="dynamic-background" :class="$store.state.themeMode" />

</template>