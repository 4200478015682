import { PerspectiveCamera, Scene, WebGLRenderer,
         Mesh, MeshBasicMaterial, SphereGeometry,
         Vector3, Group,
         DirectionalLight, PointLight  } from 'three';
import { syncCanvasSize } from '@/system/tools';
import Text3D from '@/system/tools/Text3D';
import LogoFontRaw from '@/assets/3d-logo-font';

export function populateStarfieldInto(container) {            
    let velocity = 120; // more is less
    let density = 4; // more is less
    var camera, scene, renderer, stars = [];
    var star = null;
    camera = new PerspectiveCamera(90, window.innerWidth / window.innerHeight, 1, 1000);
    scene = new Scene();
    renderer = new WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);

    spawnK93DLogo(container, scene, camera);

    for (let z = -1000; z < 1000; z += density) {
        var geometry = new SphereGeometry(0.4, 16, 32);
        let opacity = Math.random() * 0.95;
        let color = (Math.random() < 0.5) ? 0xbbddff : 0xffffff;
        var material = new MeshBasicMaterial({ color, opacity, transparent: true })
        var sphere = new Mesh(geometry, material);
        sphere.position.x = Math.random() * 1000 - 500;
        sphere.position.y = Math.random() * 1000 - 500;
        sphere.position.z = z;
        sphere.scale.x = sphere.scale.y = 2;
        sphere.scale.z = 5;
        scene.add(sphere);
        stars.push(sphere);
    }
    function render() { // Stars
        syncCanvasSize(renderer.domElement, renderer, camera);
        requestAnimationFrame(render);
        renderer.render(scene, camera);
        for (let i = 0; i < stars.length; i++) {
            star = stars[i];    
            star.position.z +=  i/velocity;
            if (star.position.z > 1000) {
                star.position.z -= 2000;
            }
        }
    }
    render(); // Stars
}

export var Widgets = { // Stub
};

// var group;

// let pointerX = 0;
// let pointerXOnPointerDown = 0;
// let windowHalfX = window.innerWidth / 2;
// var group;
// targetRotation = 0;
// targetRotationOnPointerDown = 0;
// renderer;

// refreshText3D?
function refresh() {
    group.remove(logoText);
    if (!text) {
        return;
    }
    this.show();
}

function onPointerDown(event) {
    if (event.isPrimary === false) {
        return;
    }
    pointerXOnPointerDown = event.clientX - windowHalfX;
    targetRotationOnPointerDown = targetRotation;
    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);
}
function onPointerMove(event) {
    if (event.isPrimary === false) {
        return;
    }
    pointerX = event.clientX - windowHalfX;
    console.log("Target rotation on pointer down:", targetRotationOnPointerDown);
    targetRotation = targetRotationOnPointerDown + (pointerX - pointerXOnPointerDown) * 0.02;
}
function onPointerUp() {
    if (event.isPrimary === false) { // TODO: This is an error?
        return;
    }
    document.removeEventListener('pointermove', onPointerMove);
    document.removeEventListener('pointerup', onPointerUp);
}

function spawnK93DLogo(container, scene, camera) {
    let    cameraTarget = new Vector3(0, 0, 0);
    var        renderer = new WebGLRenderer({ antialias: true });
    let           group = new Group();
    let        dirLight = new DirectionalLight(0xffffff, 0.125);
    let      pointLight = new PointLight(0xffffff, 1.5);

    // scene.fog = new THREE.Fog(0x000000, 250, 1400);
    dirLight.position.set(0, 0, 1).normalize();
    pointLight.position.set(0, 10, 5);
    //pointLight.color.setHSL(Math.random(), 1, 0.5);
    //pointLight.color.setHSL(200/360, 1, .5);

    scene.add(dirLight);
    scene.add(pointLight);

    group.position.x = -18
    group.position.y = 0;
    group.position.z = -500;

    var logo = new Text3D(LogoFontRaw, renderer, group, 'K9');
    
    // console.log("Logo Hull:");
    // console.log(logo.hull);

    group.add(logo.mesh);
    scene.add(group);

    // Wait for CSS animations to "finish"  
    setTimeout(() => {
        logo.show('fadeIn');
    }, 12000);

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);
    container.style.touchAction = 'none';
    //container.addEventListener('pointerdown', onPointerDown);
    // window.addEventListener('resize', function() {
    //     windowHalfX = window.innerWidth / 2;
    //     camera.aspect = window.innerWidth / window.innerHeight;
    //     camera.updateProjectionMatrix();
    //     renderer.setSize(window.innerWidth, window.innerHeight);
    // });
    // }

    // This used to be nested one layer deeper, and it lived in the Font object
    function render(scene, camera, cameraTarget) {  // TODO: Refactor or at least review args
        // group.rotation.y += (targetRotation) * 0.05;
        renderer.clear();
        renderer.render(scene, camera, cameraTarget);
    }
    function animate() { // Leave arguments out of this or it will pass corrupt values in requestAnimationFrame
        requestAnimationFrame(animate); // 
        render(scene, camera, cameraTarget);
    }

    animate();
}