import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import styled from 'vue-styled-components';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fab); // for LinkedIn et al.
library.add(fas); // everything else

Vue.component('FontAwesome', FontAwesomeIcon);

document.addEventListener('keyup', (event) => {
    if (event.key === 'Escape') {
        if (store.state.roiTrayVisible) {
            store.dispatch('toggleROITray');
        }

        store.dispatch('dismissAllModals');
	}
});

new Vue({
	router,
	store,
	styled,
	render: h => h(App)
}).$mount('#app');