<script>

	import { Widgets } from './FAQTab.vh';

	export default {
		name: 'faq-tab',
        components: {
            FAQTab: Widgets.FAQTab
        }
	}
    
</script>

<template>

	<FAQTab id="faq-tab"
		@bevel-bar-clicked="$store.dispatch('toggleROITray')">
			
        <FontAwesome icon="question-circle" />FAQ
	</FAQTab>

</template>