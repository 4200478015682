import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';

Vue.use(Vuex);

let n = 1;

export default new Vuex.Store({
	strict: true,

	state: {
        initialRoute: null, // We have to start out in "/" so App uses this to handle rerouting
        readingMode: false, // Whether we are trying to read a modal or flipped card etc.
        readingModeVotes: 0, // The number of elements "asking" that we stay in reading mode
		// deprecated cardsTouched: false, // Whether the user has ever interacted with a card in this session
		roiTrayVisible: false, // Flipped state, this is different from activeMenus which is for "suppressed" state
		pupBoyOpen: false, // On mobile we show a different menu, it's collapsable and starts closed
        theaterMode: false, // Whether we render the lightbox background overlay
		theaterScreenDepth: 9999, // Start over everything, change by developer request
		themeMode: 'light',
		desiredTopic: null, // When the user clicks on a new topic, we try to travel to it, this is soon after invalidated
		topic: null, // The topic we are actually on
        activeModals: {
            about: false,
            contact: false,
			phoneContact: false, // TODO: DELETEME
			image: false
		},
		activeMenus: { // Whether the menus are interactable and whether their contents are shown
			roi: false,
			fx: false,
			businessServices: false,
			contact: false,
		},
		activeTabs: {
            strategy: false,
            design: false,
            tech: false,
            faq: false,
            about: false,
            contact: false
		},
		routerScrollPosition: -1,
        scrollables: { // Indexed by VueComonent::_uid
            // context: VNode GOTCHA: You cannot pass around VueComponent or $el in Vuex
            // position: Number
        }, 
        // scrollables[vnode] = position
        // scroll: {
        //     context: null,
        //     position: -1 // px
        // },
        activeCard: {
            scrollSubscriptions: []
        },
		routerContentHeight: 0
	},

	mutations: {
        saveInitialRoute: (state, initialRoute) => {
            state.initialRoute = initialRoute;
        },
		notifyCardTouched: (state) => {
			state.cardsTouched = true;  
		},
        requestReadingMode: (state) => {
            state.readingModeVotes++;

            if (state.readingModeVotes > 0) {
                state.readingMode = true;
            } else {
                state.readingMode = false;
            }
        },
        releaseReadingMode: (state) => {
            state.readingModeVotes--;

            if (state.readingModeVotes > 0) {
                state.readingMode = true;
            } else {
                state.readingMode = false;
            }            
        },
		setVolume: (state, newVolume) => {
			state.volume = Number(newVolume);
		},
		toggleTheme: (state) => {
			state.themeMode = (state.themeMode === 'dark') ? 'light' : 'dark';
		},
		routerScrollEvent: (state, routerElement) => {
			state.desiredTopic = null; // FENCE: Dirty flag, so clicking the same topic again will still jump later
			state.routerScrollPosition = routerElement.scrollTop;
			state.routerContentHeight = routerElement.scrollHeight;
		},
        activeCardScrollEvent: (state, cardBackElement) => {
            let hull = cardBackElement.getBoundingClientRect();
            state.activeCard.scrollSubscriptions.forEach((subscription) => {
                subscription(hull);
            });
        },
        enableScrollAuditing: (state, scrollable) => {
            // FENCE: Keep this for later, the dataflow is deep
            // if (! state.scrollables[scrollable._uid]) {
            //     console.warn("ENABLED AUDITING FOR " + scrollable._uid + "(" + scrollable.$vnode?.tag + ")");
            // } else {
            //     console.error("Refreshing auditing for extant scrollable: " + scrollable._uid + "(" + scrollable.$vnode?.tag + ")");
            // }

            // FENCE: Keep this for later, this element is strange
            // if (scrollable._uid === 2) {
            //     console.log("ENABLING AUDITING ON: " + scrollable._uid);
            //     for (var key in scrollable) {
            //         console.log(key + ": " + scrollable[key]);
            //         // console.log(scrollable);
            //     }
            // }
            Vue.set(state.scrollables, scrollable._uid, {
                context: scrollable.$vnode, // DEBUG: This is the problem line
                position: scrollable.$el.scrollTop
            });
        },
        auditScrollEvent: (state, event) => {
            // console.log("auditScrollEvent node:");
            // console.log(event);
            // console.log("#" + event.srcElement.__vue__._uid);
            // console.log("@ " + event.srcElement.scrollTop);
            // FENCE: Keep this debug comment here - console.error("AUDITING EVENT:");
            //                                     - console.dir(event);
            Vue.set(state.scrollables, event.srcElement.__vue__._uid, {
                context: event.srcElement.__vue__._vnode,
                position: event.srcElement.scrollTop
            });

            // Incomplete architecture, use Big Pointer
            // state.scrollables[event.srcElement.__vue__._uid].position = event.srcElement.scrollTop;
        },
        // TODO: This should eat all the others
        activeCardScrollSubscribe: (state, subscription) => {
            state.activeCard.scrollSubscriptions.push(subscription);
        },
        // SMELL TODO: Since there's only one mouse, we can combine all "activeScroll" events into one
        //  It would be good to do this, then just let all components listen to "the active scroller"

		setTopic: (state, newTopic) => {
			_.forOwn(state.activeModals, (modalState, key) => {
				if (modalState === true) {
					state.activeModals[key] = false;
				}
			});
			state.topic = newTopic;
			/// the router will scroll itself
		},
		setDesiredTopic: (state, newDesiredTopic, forceCardFlip) => {
			state.desiredTopic = newDesiredTopic;

            // if (forceCardFlip) {

            // }
		},
		toggleMenuInteraction: (state, menuName) => {
			state.activeMenus[menuName] = !state.activeMenus[menuName];
		},
		toggleTabInteraction: (state, tabName) => {
			state.activeTabs[tabName] = !state.activeTabs[tabName];
		},
		disableMenuInteraction: (state, menuName) => {
			state.activeMenus[menuName] = false;			
		},
		enableMenuInteraction: (state, menuName) => {
			state.activeMenus[menuName] = true;			
		},
		toggleTheaterMode: (state, zIndex) => {
			state.theaterScreenDepth = zIndex ?? state.theaterScreenDepth;
			state.theaterMode = !state.theaterMode;
		},
		openROITray: (state) => {
            _.forOwn(state.activeModals, (modalState, key) => {
				if (modalState === true) {
					state.activeModals[key] = false;
				}
			});
			state.roiTrayVisible = true;
		},
		closeROITray: (state) => {
			state.roiTrayVisible = false;
		},
        togglePupBoy: (state) => {
            state.pupBoyOpen = !state.pupBoyOpen;
        },
        openModal: (state, key) => {
            if (state.activeModals[key] === true) { // Reset it if we refire
                state.activeModals[key] = false;
            }

			state.activeModals[key] = true;
        },
        closeModal: (state, key) => {
            state.activeModals[key] = false;
        }
	},

	// In case we need async stuff or multiple mutations at once
	actions: {
		openAboutModal: ({commit, state}) => {
            commit('openModal', 'about');
            commit('requestReadingMode');
		},        
		openContactModal: ({commit, state}) => {
            commit('openModal', 'contact');
            commit('requestReadingMode');
		},
		openImageModal: ({commit, state}) => {
            commit('openModal', 'image');
            commit('requestReadingMode');
		},
		dismissAllModals: ({commit, state}) => {
			_.forOwn(state.activeModals, (modalState, key) => {
				if (modalState === true) {
					commit('closeModal', key);
                    commit('releaseReadingMode');
				}
			});
		},
		toggleROITray: ({commit, state}) => {
			commit('toggleTheaterMode', 500);

			if (!state.roiTrayVisible) {
				commit('openROITray');
                commit('requestReadingMode');
			} else {
				commit('closeROITray');
                commit('releaseReadingMode');
			}
		},
		theaterScreenClicked: ({commit, state}) => {
			if (state.roiTrayVisible) {
				commit('closeROITray');
			}

			// TODO: Add more events as screens are reused... Maybe make this per-component after all?
			commit('toggleTheaterMode');
		}
	}
});
