<script>
	import { ROITray, Widgets } from './ROITray.vh';

	import StockGraph from '@/assets/design-forward-stock-prices.svg';
	import ChessImage from '@/assets/chess-vision.jpeg';
    import FrameHackImage from '@/assets/frame-hack.jpg';
	import UnhappyUserImage from '@/assets/unhappy-user.jpg';

	export default {
		name: 'roi-tray',
		components: {
			ROITray,
			ROIPrioritiesHeader: Widgets.ROIPrioritiesHeader,			
			StrengthQuarter: Widgets.StrengthQuarter,
			VisionQuarter: Widgets.VisionQuarter,
			SecurityQuarter: Widgets.SecurityQuarter,
			GrowthQuarter: Widgets.GrowthQuarter,
			ROIPrioritiesFooter: Widgets.ROIPrioritiesFooter,
			ROIContentLayer: Widgets.ROIContentLayer,
			ROIContentHairlineTop: Widgets.ROIContentHairlineTop,
			ROIContentHairlineLeft: Widgets.ROIContentHairlineLeft,
			ROIContentBackButton: Widgets.ROIContentBackButton,
			CollapseToggle: Widgets.CollapseToggle
		},
		data: () => ({
			activeAttribute: null, // strength, vision, security, or growth - this mirrors a traditional SWOT analysis
			history: Array,
			StockGraph,
            ChessImage,
			FrameHackImage,
			UnhappyUserImage
		}),
		methods: {
			toggleAttribute: function(newAttribute) {
				if (!this.activeAttribute) {
					this.activeAttribute = newAttribute;
				} else {
					this.activeAttribute = null;
				}
			},
			navigateTo: function(newAttribute) {
				this.history.push(this.activeAttribute);
				this.activeAttribute = newAttribute;
			},
			setActiveAttribute(newAttribute) {
				this.activeAttribute = newAttribute;
			},
			goBack: function() {
				let destination = this.history.pop() || null;
				this.setActiveAttribute(destination);
			},
			joke: function() {
				alert('OH NO!');
			}
		}
	}

</script>

<template>

	<ROITray id="roi-tray" 
		:class="[{visible: $store.state.roiTrayVisible}, $store.state.themeMode]">

			<ROIContentLayer :class="activeAttribute ? 'visible' : 'hidden'"> 
				<ROIContentHairlineTop />
				<ROIContentHairlineLeft />
				<ROIContentBackButton @click="toggleAttribute(activeAttribute)">
					<FontAwesome icon="arrow-circle-left" />
				</ROIContentBackButton>

				<div id="strength-content" v-if="activeAttribute === 'strength'">
					<p class="roi-content-header">
						Strength
					</p>
					<p class="roi-content-body">
						<img :src="StockGraph" />
						Companies that prioritize the user experience consistently outperform the S&P index by more than double.  From Apple to Ford and Nike, these firms consistently prioritize their customer-facing digital presence and consistently rank as the leaders in the market.<br /><br>
						Any brand in the modern age that wants to leverage its strength and operate powerfully in the current environment needs an exceptional digital presence.  If this is you, kindly <a class="link text" href="mailto:explore@k9interactive.com?subject=Business Strength Inquiry For <Your Awesome Company>">reach out to get more information</a> about what we can accomplish together, and how to become even stronger.
					</p>
				</div>
				<div id="vision-content" v-if="activeAttribute === 'vision'">
					<p class="roi-content-header">
						Vision
					</p>
					<p class="roi-content-body">
						<img :src="ChessImage" />
						Opportunities in the current economic landscape truly center around the use of digital strategy to succeed.  Not only do the top billionaires overwhelmingly work in technology and design, but since the Coronavirus pandemic, the need for a strong digital presence has become front-and-center for everyone.  Perhaps the best illustration of the vital nature of a firm's digital presence is a story told about what is colloquially referred to as "The 300 Million Dollar Button" - it's a story involving the UX behind the UI.<br />
						<button @click="joke()">CLICK TO LOSE $300MM ???</button><br />
						That is, the company did not understand the human factors psychology involved in placing certain invisible barriers to the customer journey.  In doing so, they added business logic to a simple button that cost them an estimated $300MM until a clever research team solved the issue.<br /><br />K9 specializes in this type of design thinking, so if you are curious, kindly <a class="link text" href="mailto:explore@k9interactive.com?subject=Vision Inquiry For <Your Awesome Company>">request information on how K9 can help</a>. Issues like this story come up all the time, and like in other aspects of business, experience and attention to detail make all the difference in the world.
					</p>
				</div>
				<div id="security-content" v-if="activeAttribute === 'security'">
					<p class="roi-content-header">
						Security
					</p>
					<p class="roi-content-body">
						<img :src="FrameHackImage" />
						Threats in the current environment exist in many forms.  On the modern threat landscape there are information security vulnerabilities, cross-site scripting attacks, phishing, viral payloads, and numerous other attacks which if not skillfully deflected can bring down a corporation of any size requiring days, weeks, or months of repair work (if it ever goes back to normal at all).<br /><br />
						Meanwhile, advanced threat actors are always at work, maddeningly hammering against any system they deem "juicy enough" to warrant painting a target upon.<br /><br />If that was not enough, competitors are always innovating.  They work day and night to get a leg up on the competition.  End users and even B2B clients are coming to expect a modern and glitch-free experience from everyone they do business with.  Do you own formal attire for business?  Then why do anything else on the web?  <a class="link text" href="mailto:explore@k9interactive.com?subject=Threat Management Inquiry for <Your Awesome Company>">Let K9 be both your tailor and armorsmith</a> in this uncertain world.
					</p>
				</div>
				<div id="growth-content" v-if="activeAttribute === 'growth'">
					<p class="roi-content-header">
						Growth
					</p>
					<p class="roi-content-body">
						<img :src="UnhappyUserImage" />
						A weakness in customer experience is also a crippling weakness in all business that you engage in.  This is true now more than ever with the onset and aftermath of Covid-19.<br /><br />
						Top performers rank customer experience with a heavy hand:  IBM reported a 50% reduction in maintenance costs and a 300% return on millions of dollars invested into their UX years ago.  The research team at Forrester echoes and amplifies these numbers calling digital and user experience design a 1000% (one thousand) percent return on investemnt.<br /><br />
						Top-ranking companies regularly spend over 10% of their total project budgets on the design and ideation of great digital products for one simple reason: it works.<br /><br />
						The tasks needed in the professional execution of best-in-class digital systems are many and varied, including environmental analysis, requirements gathering, prototyping, A-B testing, user studies, collaboration, engineering, and much more.  You can do it all in-house, or you can hire professionals who eat, breathe, and sleep these disciplines daily.<br /><br />
						If you would like to inquire about the latter, kindly <a class="link text" href="mailto:explore@k9interactive.com?subject=Growth Inquiry For <Your Awesome Company>">reach out to K9</a> with no expense or obligation.  It's always great to hear excited people talk about their dreams.
					</p>
				</div>
			</ROIContentLayer>

			<ROIPrioritiesHeader>Return on Design Investment</ROIPrioritiesHeader>

			<StrengthQuarter 
				@click="toggleAttribute('strength')"
				:class="activeAttribute === 'strength' ? 'active' : 'idle'">
				<p class="label">
					Strength
				</p>
			</StrengthQuarter>

			<VisionQuarter
				@click="toggleAttribute('vision')"
				:class="activeAttribute === 'vision' ? 'active' : 'idle'">
				<p class="label">
					Vision
				</p>
			</VisionQuarter>

			<SecurityQuarter
				@click="toggleAttribute('security')"
				:class="activeAttribute === 'security' ? 'active' : 'idle'">
				<p class="label">
					Security
				</p>
			</SecurityQuarter>
			
			<GrowthQuarter
				@click="toggleAttribute('growth')"
				:class="activeAttribute === 'growth' ? 'active' : 'idle'">
				<p class="label">
					Growth
				</p>
			</GrowthQuarter>

			<ROIPrioritiesFooter>What matters most to you?</ROIPrioritiesFooter>

		<CollapseToggle @click="$store.dispatch('toggleROITray')">
			<FontAwesome :class="$store.state.themeMode" icon="caret-up" />
		</CollapseToggle>

	</ROITray>

</template>