<script>
	import { StyledModal, Widgets } from './About.vh';

	export default {
		name: 'modal-about',
		components: {
			StyledModal,
			//CallLink: Widgets.CallLink,
			// CloseButton: Widgets.CloseButton
		}
	}
</script>

<style lang="scss" scoped>
	.call-link {
		text-decoration: none;
	}

    .about-modal-body {
        & > p {
            font-size: 24px;
            letter-spacing: 0.25px;
            line-height: 36px;
            margin: 12px;
        }
    }
</style>

<template>
	<StyledModal>

		<template v-slot:header>
			About K9
		</template>

		<template v-slot:body>
            <div class="about-modal-body">
                <p>K9 is unique among small businesses for incredible technical depth within a design paradigm and results-oriented product vision.</p>
                <p>Keeping business context while working on the details is a tough trick to master, but we're the experts.</p>
                <p>There are a lot of teams out there, but our combination of businss acumen, design sense, and technical mastery introduces harmony where silos could be, and the smallness of our expert team won't break the bank.</p>
                <p><a class="link text" href="mailto:explore@k9interactive.com?subject=Curious About K9 Interactive">Reach out if you're curious or interested.</a> There's no hard-sell and you can take your time...  So don't worry.  <i>We're friendly.</i> 🐾</p>
            
            </div>
        </template>

		<!-- <template v-slot:footer>
			<CloseButton @click="$store.dispatch('dismissAllModals')">
				CLOSE
			</CloseButton>
		</template> -->

	</StyledModal>
</template>