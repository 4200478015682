export default {
    // MENU_ROI: 1,
    // MENU_CONTACT: 1.3, // Was 2.1
    // MENU_BUSINESS_SERVICES: 1.8,

    // Flip in fast
    // TAB_STRATEGY: 1.20,
    // TAB_DESIGN: 1.10,
    // TAB_TECH: 1,
    // TAB_FAQ: 1.05,
    // TAB_ABOUT: 1.15,
    // TAB_CONTACT: 1.25,

    // Sweep in right
    // TAB_STRATEGY: 1.00,
    // TAB_DESIGN: 1.05,
    // TAB_TECH: 1.10,
    // TAB_FAQ: 1.15,
    // TAB_ABOUT: 1.20,
    // TAB_CONTACT: 1.25,

    // Sweep in right, slow
    TAB_STRATEGY: 0.50, 
    TAB_DESIGN: 0.60,
    TAB_TECH: 0.70,
    TAB_FAQ: 0.80,
    TAB_ABOUT: 0.90,
    TAB_CONTACT: 1.00, // Match PAGE_ANIMATIONS_FINISHED
    PAGE_ANIMATIONS_FINISHED: 1.25, // Match TAB_CONTACT

    // Sweep in half right, pause, sweep in half left
    // TAB_STRATEGY: 1.00,
    // TAB_DESIGN: 1.05,
    // TAB_TECH: 1.10,
    // TAB_FAQ: 2.05,
    // TAB_ABOUT: 1.8,
    // TAB_CONTACT: 1.75,

    // Flip toward user
    // TAB_STRATEGY: 1.20,
    // TAB_DESIGN: 1.10,
    // TAB_TECH: 1.00,
    // TAB_FAQ: 1.00,
    // TAB_ABOUT: 1.10,
    // TAB_CONTACT: 1.20,

    // Chase vanishing point
    // TAB_STRATEGY: 1.00,
    // TAB_DESIGN: 1.10,
    // TAB_TECH: 1.20,
    // TAB_FAQ: 1.25,
    // TAB_ABOUT: 1.15,
    // TAB_CONTACT: 1.05,

    INTRO_DOOR_PAUSE: 3.5,
    INTRO_HDR_FADE_START: 3.5,

    INTRO_HYPERSPACE_DURATION: 5,
    INTRO_ANIMATION_DURATION: 17,

    INTRO_DOG_FADE_IN_DELAY: 8
}