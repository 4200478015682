<script>
    import { Widgets } from './BevelBar.vh';
	import store from '@/system/store';

    export default {
        name: 'bevel-bar',
        props: {
            slope: Number,
            slopeLeft: Number,
            slopeRight: Number,
            align: String,
            enabled: Boolean // Check this with (enabled !== false) so that if it isn't set, we assume the bar is online and working
        },
        components: {
            BevelBar: Widgets.BevelBar,
            BlockingOverlay: Widgets.BlockingOverlay,
            ContentArea: Widgets.ContentArea,
            LeftHalf: Widgets.LeftHalf,
            RightHalf: Widgets.RightHalf,
            LeftBackground: Widgets.LeftBackground,
            RightBackground: Widgets.RightBackground
        },
        data: () => ({
            hovering: false // FENCE: This is for whether either of the background halves are hovered, giving pixel-perfect accuracy vs just using css
        }),
        methods: {
            computeHeight: function() { // HACKHACK
                if (store.state.roiTrayVisible) {
                    return 'height: 0px !important';
                } else if (store.state.topic) {
                    return 'height: 50px !important';
                } else {
                    return ''
                }
            }
        }
    }
</script>

<template>

    <BevelBar class="bevel-bar" :class="hovering ? 'bar-hover' : ''"
            :style="computeHeight()">
        <BlockingOverlay class="ui-toggle" />

        <ContentArea :align="align" class="content-area">
            <slot></slot>
        </ContentArea>

        <LeftHalf>
            <LeftBackground
                @click="$emit('bevel-bar-clicked')" 
                @mouseenter="hovering = true"
                @mouseleave="hovering = false"
                :slope="slope"
                :slopeLeft="slopeLeft"
                :align="align"
            />
        </LeftHalf>

        <RightHalf>
            <RightBackground
                @click="$emit('bevel-bar-clicked')"
                @mouseenter="hovering = true"
                @mouseleave="hovering = false"
                :slope="slope"
                :slopeRight="slopeRight"
                :align="align"
            />
        </RightHalf>

    </BevelBar>

</template>