<script>

    import { BackdropScreen } from './BackdropScreen.vh';

	export default {
        name: 'backdrop-screen',
        components: {
            BackdropScreen
        }
	}

</script>

<template>

    <BackdropScreen
        v-if="$store.state.roiTrayVisible"
        @click="$store.dispatch('toggleROITray')" />

</template>