<script>
    import store from '@/system/store';

    import { Widgets } from './Topic.vh';
	
	export default {
        name: 'topic',
        components: {
            PageSection: Widgets.PageSection
        },
        computed: {
			routerScrollPosition: () => store.state.routerScrollPosition
		},
        watch: {
            routerScrollPosition: function(newScrollPosition) {
                let hitscan = newScrollPosition + (window.innerHeight/2); // Screen center, to scan for collisions
                let top = this.$el.offsetTop;
                let bot = top + this.$el.offsetHeight;
                let id = this.$attrs.id;
                if (top < hitscan && bot > hitscan) { // Hit detected in center of viewport
                    store.commit('setTopic', id);
                }
            }
        }
	}

</script>

<template>

    <PageSection>
        <slot></slot>
    </PageSection>

</template>