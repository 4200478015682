import styled from 'vue-styled-components';
import Card from '@/components/Card.vue';

import OverfoldImage from '@/assets/overfold.png';
import DimeQuestImage from '@/assets/dime-quest.png';
import BioMinderImage from '@/assets/bio-minder.png';

// Styled-Components breaks calling member functions by ref, don't use these without a fix:
// export var Cards = {
//     Strategy: styled(Card)`
//         float: right;
//         width: 40vw;
//         height: 26vw;
//         margin-left: 6vw;
//     `,
//     UX: styled(Card)`
//         float: left;
//         width: 35vw;
//         height: 32vw;
//         margin-right: 6vw;
//     `,
//     UI: styled(Card)`
//         float: right;
//         margin-left: 6vw;
//         width: 36vw;
//         height: 36vw;
//     `,
//     Production: styled(Card)`
//         float: left;
//         margin-right: 6vw;
//         width: 38vw;
//         height: 30vw;
//     `,
//     ComprehensiveSolutions: styled(Card)`
//         float: right;
//         margin-left: 6vw;
//         width: 40vw;
//         height: 28vw;
//     `
// };

export var Widgets = {
    Home: styled.div`
        text-align: left;
        width: 80%;
        padding-left: 10%;
        height: 80%;
        padding-top: 10%;
    `,
    Footer: styled.div`
        position: relative;
        float: left;
        border-top: 20px solid gray;
        padding-top: 32px;
        width: 100vw;
        min-height: 150px;
        margin-left: -10vw;

        font-size: 11px;
        text-align: center;
        background-color: #DDD;
    `,
    CopyrightNotice: styled.p`
        font-size: 12px !important; // 5vmin;
    `,
    Emblem: styled.img`
        height: 15vmin;
        margin-bottom: 7.5vh;

        opacity: 70%;
    `,
    Disclaimer: styled.p`
        font-size: 12px !important;
        margin: 0px !important;
    `,

    DuplexSection: styled.div`
        &:first-of-type { margin-top: 10vh; }

        display: flex;
        align-items: center;
        min-height: 300px;
        color: rgb(200,200,200) !important;
        align-items: left;
        justify-content: center; // For mobile devices
        flex-wrap: wrap;

        & > .duplex-image, & > .mini-card {
            margin-right: 2.5vw;
            flex-shrink: 0;
            flex-grow: 0;

            // Mobile is a vertical display
            @media only screen and (max-width: 1025px) {
                margin-top: 5vh;
                margin-bottom: 2.5vh;
            }
        }

        & > p {
            color: rgb(200,200,200) !important;
            text-align: left;
            //min-width: 0px;
            flex-basis: calc(100% - 250px - 2.5vw);
            flex-shrink: 0;
            flex-grow: 1;

            // Mobile is a vertical display
            @media only screen and (max-width: 1025px) {
                text-align: center !important;
            }
        }

        &.right {
            /* align-items: right; */
            flex-wrap: wrap-reverse;

            & > .duplex-image, & > .mini-card {
                order: 2; // Allow consistent templating but count it as later in the dom for flexing
                margin-left: 2.5vw;
                margin-right: 0px;
            }

            & > p {
                text-align: right;
            }
        }

        &:last-of-type { margin-bottom: 10vh; }
    `,
    OverfoldImage: styled.img`
        content: url(${OverfoldImage});
        width: 100%;
        height: auto;
    `,
    BioMinderImage: styled.img`
        content: url(${BioMinderImage});
        width: 100%;
        height: auto;
    `,
    DimeQuestImage: styled.img`
        content: url(${DimeQuestImage});
        width: 100%;
        height: auto;
    `
};