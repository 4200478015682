import styled from 'vue-styled-components';

export var Widgets = {
	Screen: styled.div`
		transition: background-color 1s;

		position: fixed;
		z-index: 9999;
		width: 100vw;
		height: 100vh;
		left: 0px;
		top: 0px;

		cursor: pointer;
		background-color: rgba(0,0,0, 0.5);

		&.active {
		}

		&.disabled {
			width: 0px;
			height: 0px;
			pointer-events: none;
			cursor: default;
		}
	`
}