<script>

	import { Widgets } from './TechTab.vh';

	export default {
		name: 'tech-tab',
        components: {
            TechTab: Widgets.TechTab
        }
	}
    
</script>

<template>

	<TechTab id="tech-tab" :style="!$store.state.roiTrayVisible && $store.state.topic === 'topic-ui' ? 'height: 65px !important' : ''"
		:class="$store.state.topic === 'topic-ui' ? 'active' : 'idle'"
		@bevel-bar-clicked="$store.commit('setDesiredTopic', 'topic-ui')">
			
        <FontAwesome icon="code" />Tech
	</TechTab>

</template>