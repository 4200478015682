<script>

	import { Widgets } from './DesignTab.vh';

	export default {
		name: 'design-tab',
        components: {
            DesignTab: Widgets.DesignTab
        }
	}
    
</script>

<template>

	<DesignTab id="design-tab" :style="!$store.state.roiTrayVisible && $store.state.topic === 'topic-ux' ? 'height: 65px !important' : ''"
		:class="$store.state.topic === 'topic-ux' ? 'active' : 'idle'"
		@bevel-bar-clicked="$store.commit('setDesiredTopic', 'topic-ux')">
			
        <FontAwesome icon="drafting-compass" />Design
	</DesignTab>

</template>