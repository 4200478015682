<script>

	import { Widgets } from './ContactTab.vh';

	export default {
		name: 'contact-tab',
        components: {
            ContactTab: Widgets.ContactTab
        }
	}
    
</script>

<template>

	<ContactTab id="contact-tab" :style="!$store.state.roiTrayVisible && $store.state.activeModals.contact ? 'height: 65px !important' : ''"
		@bevel-bar-clicked="$store.dispatch('openContactModal')">

        <FontAwesome icon="envelope" />Contact
	</ContactTab>

</template>