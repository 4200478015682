<script>
	// import store from '@/system/store';

	import { Widgets, populateStarfieldInto } from './Space.vh';

	export default {
		name: 'space-hero',
		components: {
		},
		data: () => ({
		}),
		methods: {
        },
        mounted() {
            populateStarfieldInto(this.$refs['three-space']);
        }
	}

</script>

<style lang="scss" scoped>

    .three-space {
        @keyframes FadeInSpace {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        } 
 
        user-select: none;
		animation: 2s FadeInSpace forwards;
        opacity: 0;
        height: 100%;
        width: 100%;
    }

</style>

<template>
    <div ref="three-space" class="three-space"></div>
</template>