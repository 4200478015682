<script>

    // SMELL:
    // The styling override on the chevron is having to re-hardcode its re-scaling

    // ALSO: the following line will re-enable "click and we vanish" on the cards
    // :class="$store.state.cardsTouched ? 'hidden' : 'visible'" 

    // ALSO: The z-index of each card's "cardmodal background" when flipped, is hardcoded

    // ALSO: We should probably remove suppressRouting and use "router guards"

	import store from '@/system/store';
    import { Widgets, FLIP_ANIMATION_DURATION } from './Card.vh';
    import Chevron from '@/components/Chevron.vue';

    // GOTCHA: The big thing to know about this component is that when you set the baseHull it will implicitly try to animate toward it

    function pathMatches(route, id) {
        return  (route === '/strategy' && id === 'card-strategy') ||
                (route === '/design' && id === 'card-ux') ||
                (route === '/tech' && id === 'card-ui');
    }

    var hotkeyHandler = null;

	export default {
        name: 'card',
        props: {
            image: String,
            align: String // left, center, right
        },
        components: {
            //ClickIndicator: Widgets.ClickIndicator,
            CardSpace: Widgets.CardSpace,
            AnimationLayer: Widgets.AnimationLayer,
            Card: Widgets.Card,
            Front: Widgets.Front,
            Back: Widgets.Back,
            Chevron
        },
        data: () => ({ 
            that: null, // DELETEME
            flipped: false, // The Card is bound to this value
            elevated: false, // The AnimationLayer is bound to this one
            mode: 'card', // We need to know which "mode" we are in when we compute styles (card | modal)
            baseHull: null, // The CardSpace where we belong as a geometry (setting/unsetting recomputes styles)
            tween: null, // Timer.  Used to suppress interaction while we tween
            baseScrollPosition: null, // Where the router scroll was when we started flipping
            animationLayerOffset: null, // Correction amount for the AnimationLayer so it will stick in place
            cardPercentTop: 0, // Value is stored once a zoomIn finishes to calculate its return position statically
            promptAwaitingScroll: true // Bottom floating indicator to scroll
        }),
        created() {
            hotkeyHandler = document.addEventListener('keyup', (event) => {
                if (event.key === 'Escape' && this.flipped) {
                    this.flip();
                }
            });
        },
        mounted() {
            // console.log("Creating context");
            this.that = this;

            if (store.state.initialRoute) {
                let route = '/' + store.state.initialRoute;
                let id = this.$el.id;

                // If we come in on a route hyperlink, it's it's our time to shine, then shine
                if (pathMatches(route, id) && !this.flipped) {
                    this.flip();
                }
            }
        },
        beforeDestroy() {
            document.removeEventListener(hotkeyHandler);
        },
        methods: {
            clampAnimationLayer() { // Allows us to stick the animation context in place
                let offset = this.baseScrollPosition - store.state.routerScrollPosition;
                this.animationLayerOffset = `top: ${offset}px`;
            },
            freeAnimationLayer() {
                this.animationLayerOffset = `top: 0px`;
            },
            zoomIn(suppressRouting) {
                if (!suppressRouting) {
                    let id = this.$el.id;
                    let path =  (id === 'card-strategy')    ? 'strategy':
                                (id === 'card-ux')          ? 'design'  :
                                (id === 'card-ui')          ? 'tech' :
                                'ERROR_INVALID_ROUTE';

                    if (path !== 'ERROR_INVALID_ROUTE') {
                        this.$router.push('/' + path);
                    }
                }

                store.commit('requestReadingMode');

                this.baseScrollPosition = store.state.routerScrollPosition;
                this.baseHull = this.$refs.base.$el.getBoundingClientRect();
                this.elevated = true;

                this.tween = setTimeout(() => { // Block click during tween
                    this.tween = null;
                }, FLIP_ANIMATION_DURATION);

                setTimeout(() => { // Pause one frame to let CSS lock-on
                    let animationLayerHeight = this.$refs.animationLayer.$el.getBoundingClientRect().height;
                    this.cardPercentTop = ((this.baseHull.top/animationLayerHeight)*100).toFixed(2);
                    this.flipped = true;
                    this.baseHull = null; // Release from baseHull lock
                    this.mode = 'modal';
                });
            },
            zoomOut(suppressRouting) {
                if (!suppressRouting) {
                    this.$router.go(-1);
                }

                store.commit('releaseReadingMode');

                this.baseHull = this.$refs.base.$el.getBoundingClientRect();
                this.flipped = false;

                this.tween = setTimeout(() => { // Wait for tween, block click
                    setTimeout(() => { // Then pause one frame to let CSS lock-on before reset
                        this.freeAnimationLayer();
                        this.mode = 'card';
                        this.elevated = false;
                        this.baseHull = null;
                        this.tween = null;
                        this.cardPercentTop = 0;
                    });
                }, FLIP_ANIMATION_DURATION);  
            },
            flip(suppressRouting) {
                if (this.tween) { return; }

                if (this.elevated) {
                    this.zoomOut(suppressRouting);
                } else {
                    this.zoomIn(suppressRouting);
                }
            },
        },
        computed: {
			routerScrollPosition: () => store.state.routerScrollPosition,
            cardLocationOverride: function() {
                if (! this.baseHull) {
                    if (this.elevated) {
                        return '';
                    } else { // Reset state (last frame full loop)
                        return 'transition-duration: 0s;';
                    }
                }

                let baseHull = this.baseHull;
                let transition = (this.mode === 'card') ? 'transition-duration: 0s;' : ''; // Cards swap display state at the end, so we snap because they are guaranteed already in-place, whereas modals need time to animate so we don't
                let left = `left: ${baseHull.left}px;`;
                let top = (this.mode === 'modal') ? `top: ${this.cardPercentTop}%;` : `top: ${baseHull.top}px;`; // Percentage to establish relative translation since world coordinates vary
                let width = `width: ${baseHull.width}px;`;
                let height = `height: ${baseHull.height}px;`;

                let styles = transition + width + height + left + top;

                return styles;
            }
		},
        watch: {
            $route (to, from) {
                if (pathMatches(to.path, this.$el.id)) {
                    if (! this.flipped) {
                        this.flip(true);
                    }
                }

                if (pathMatches(from.path, this.$el.id)) {
                    if (this.flipped) {
                        this.flip(true);
                    }
                }
            },
            routerScrollPosition: function() {
                if (this.elevated) {
                    let hull = this.$el.getBoundingClientRect();
                    let threshold = hull.height / 2;
                    
                    // TODO: window.innerheight is a limitation that will need to be refactored to work in nested sub-components
                    if ((hull.bottom - threshold) < 0 || (hull.top + threshold) > window.innerHeight)  {
                        this.flip();
                    }

                    this.clampAnimationLayer(); // When we scroll, any pinned AnimationLayers stick where they were
                }
            },

        }
	}

</script>

<style lang="scss" scoped>
    .scroll-frame {
        margin: 2%;
        width: 96%;
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 2%;
        padding-bottom: 2%;
        display: flex; // FENCE: Using content: url breaks firefox, adding alt tag to fix firefox breaks chrome, this fixes chrome.
        flex-direction: column;
        // z-index: 999; // FENCE: Overlay the border shine
    }

    .card-front-click-indicator {
        pointer-events: none;
        position: absolute;
        top: 16px;
        transform: rotateZ(-90deg) scaleX(.75);
    }

    .floating-closebox {
        position: absolute;
        pointer-events: none;
        color: black;
        background-color: gray;
        border-radius: 100px;
        cursor: pointer;
        transition: all 1s !important;
        opacity: 0;
        /* right: -500px;  */
        right: calc(5vw - 20px);

        top: 80px;
        z-index: 9999 !important;
        border-top: 1px solid #444;
        padding-top: -1px; // FENCE: This normalizes border width while keeping gradient lighting
        
        &.active {
            opacity: 1;
            /* right: calc(5vw - 20px); */
            pointer-events: all;
        }
        
        &:hover {
            background-color: rgb(240,50,50) !important;

            & > .floating-closebox-icon {
                color: white !important;
            }
        }
        & > .floating-closebox-icon {
            color: black;
        }
    }

    .floating-scroll-prompt {
        z-index: 9999;
        color: white;

        @keyframes ScrollPromptHover {
            0% {
                margin-bottom: -8px;
            }
            50% {
                margin-bottom: 0px;
            }
            100% {
                margin-bottom: -8px;
            }
        }

        animation: ScrollPromptHover 1s ease-in-out infinite;
    }

    .back-zone {
        min-width: 175px;
        &:hover { // Container
            & > p { // Label
                color: white !important;     
            }
            // The chevron runs its own internal method to brighten
        }
    }

    .back-label {
            font-size: 32px;
            font-family: Arial Black;
            font-weight: 400;
            margin-left: 12px;
            margin-top: -14px;
            color: #525252;
        }
</style>

<template>
    <CardSpace class="card-control-area" ref="base" :class="[{'flipped': flipped}]">

        <div v-if="flipped && promptAwaitingScroll" style="pointer-events: none; position: absolute; transform: scaleX(1.5); left: calc(50% - 8px); bottom: 22px; width: 2vh; height: 2vh; z-index: 9999">
            <FontAwesome icon="caret-down" class="floating-scroll-prompt" />
        </div>
        <div v-if="flipped" @click="flip()" style="position: fixed; cursor: pointer; z-index: 1000; width: 100vw; height: 100vh; top: 0px; left: 0px; background-color: rgba(0,0,0, 0.8)"></div>

        <AnimationLayer class="animation-layer" ref="animationLayer"
            :class="elevated ? 'elevated' : ''"
            :style="elevated ? animationLayerOffset : ''">

            <div @click="flip()" class="floating-closebox" :class="elevated && 'active'">
                <FontAwesome icon="times-circle" class="floating-closebox-icon" />
            </div>

            <Card class="card" ref="card" 
                :class="flipped ? 'flipped' : ''"
                :style="cardLocationOverride">

                <Front class="front" style="pointer-events: all"
                    @click="flip()" @mousedown="$event.preventDefault()"
                    :image="image">
                </Front>

                <Back class="back" :class="elevated ? 'scrollable' : ''" @scroll="promptAwaitingScroll = false">
                    <div class="scroll-frame">
                        <slot></slot>
                        <div class="back-zone" @click="flip()" @mouseover="$refs.chevron.activate()" @mouseout="$refs.chevron.deactivate()" style="pointer-events: all; cursor: pointer; display: flex; justify-content: left; align-items: center; width: 30%">
                            <Chevron ref="chevron" style="pointer-events: none; transform: rotateZ(90deg) scaleX(60%); margin-left: 12px; float: left; bottom: 12px; display: block; position: relative" />
                            <p class="back-label" style="pointer-events: none">BACK</p>
                        </div>
                    </div>
                </Back>
            
            </Card>

        </AnimationLayer>
    
    </CardSpace>

</template>