import store from '@/system/store';
const SENSOR_SCROLL = 'scroll';
const SCOPE_ANCESTORS = 'ancestors';

export default function(target, sensor, scope, delegate) {
    if (sensor === SENSOR_SCROLL) {
        if (scope === SCOPE_ANCESTORS) {
            let ancestor = target.$parent;

         // This will disable above-router scrolling
            while (ancestor?.$vnode) {
            // while (ancestor) {
                // SMELL: Yeah it touches Vue but it also automates a huge part of the consuming developer's workload - worth

                store.commit('enableScrollAuditing', ancestor);

                // FENCE: You can't do this directly in Vuex
                ancestor.$el.addEventListener('scroll', (event) => {
                    store.commit('auditScrollEvent', event);
                });

                // FENCE: It's like microwaves and gravity
                target.$watch(('$store.state.scrollables.' + ancestor._uid), delegate);
                
                ancestor = ancestor.$parent;
            }
        } else {
            console.error("Non-default awakening scopes are not yet implemented / TODO");
        }
    } else {
        console.error("Non-default awakening sensors are not yet implemented / TODO");
    }
}          



