// import * as THREE from 'three';
import { Mesh, ExtrudeGeometry, ShapePath, MeshPhongMaterial  } from 'three';

const TEXT_3D_FONT_COLOR = 'white';
const TEXT_3D_FONT_SIZE = 360;
const TEXT_3D_LINE_HEIGHT = 20;
const TEXT_3D_BEVEL_THICKNESS = 2;
const TEXT_3D_BEVEL_SIZE = 1.5;
const TEXT_3D_CURVE_SEGMENTS = 4;
const TEXT_3D_ANIMATION_SPEED = 16; // Delay, in ms, between draws (higher = slower)

export default class Text3D {
	constructor(font, renderer, group, text) {
        this.renderer = renderer;
        this.group = group;
        this.text = text;
		this.isFont = true;
		this.type = 'Text3D';

        this.material = new MeshPhongMaterial({
            color: TEXT_3D_FONT_COLOR,
            opacity: 0, // Begin hidden
            transparent: true,
            flatShading: true
        });
        this.hull = new Text3DHull(text, {
            font: font,
            size: TEXT_3D_FONT_SIZE,
            height: TEXT_3D_LINE_HEIGHT,
            curveSegments: TEXT_3D_CURVE_SEGMENTS,
            bevelThickness: TEXT_3D_BEVEL_THICKNESS,
            bevelSize: TEXT_3D_BEVEL_SIZE
        });

        // GOTCHA: the values assigned to the root object are CACHED from its children)
        this.hull.computeBoundingBox();
        this.width  = this.hull.boundingBox.max.x - this.hull.boundingBox.min.x;
        this.height = this.hull.boundingBox.max.y - this.hull.boundingBox.min.y;
        this.depth  = this.hull.boundingBox.max.z - this.hull.boundingBox.min.z;
        this.hull.translate(-this.width/2, -this.height/2, -this.depth/2);
        this.mesh = new Mesh(this.hull, [this.material, this.material]); // GOTCHA: You have to assign separate material instances to the THREE.Mesh, but after that, they update dynamically as one
    }

    show(transitionMode, transitionSpeed) {
        if (transitionMode === 'fadeIn') {
            let fading = setInterval(() => {
                (this.material.opacity < 1) ? this.material.opacity += 0.01 : clearInterval(fading);
            }, transitionSpeed ?? TEXT_3D_ANIMATION_SPEED);
        } else {
            this.material.opacity = 1;
        }
    }

    hide(transitionMode, transitionSpeed) {
        if (transitionMode === 'fadeOut') {
            let fading = setInterval(() => {
                (this.material.opacity > 0) ? this.material.opacity -= 0.01 : clearInterval(fading);
            }, transitionSpeed ?? TEXT_3D_ANIMATION_SPEED);
        } else {
            this.material.opacity = 1;
        }  
    }
}

class Text3DHull extends ExtrudeGeometry {
    constructor(text, parameters = {}) {
        let shapes = [];
        let paths = createPaths(text, parameters.size, parameters.font);
        for (let p = 0, pl = paths.length; p < pl; p++) {
            shapes.push(...paths[p].toShapes());
        }
        parameters.depth = parameters.height ?? 50;
        super(shapes, parameters);
        this.type = 'Text3DHull';
    }
}

function createPaths(text, size, data) {
	const chars = Array.from(text);
	const scale = size / data.resolution;
	const lineHeight = (data.boundingBox.yMax - data.boundingBox.yMin + data.underlineThickness) * scale;
	const paths = [];
	let offsetX = 0, offsetY = 0;
	for (let i = 0; i < chars.length; i++) {
		const char = chars[i];
		if (char === '\n') {
			offsetX = 0;
			offsetY -= lineHeight;
		} else {
			const ret = createPath(char, scale, offsetX, offsetY, data);
			offsetX += ret.offsetX;
			paths.push(ret.path);
		}
	}
	return paths;
}

function createPath(char, scale, offsetX, offsetY, data) {
	const glyph = data.glyphs[char] ?? data.glyphs['?'];
	if (! glyph) {
		console.error('THREE.Font: character "' + char + '" does not exists in font family ' + data.familyName + '.');
		return;
	}
	const path = new ShapePath();
	let x, y, cpx, cpy, cpx1, cpy1, cpx2, cpy2;
	if (glyph.o) {
		const outline = glyph._cachedOutline ?? (glyph._cachedOutline = glyph.o.split(' '));
		for (let i = 0, l = outline.length; i < l; ) {
			const action = outline[i++];
            if (action === 'm') {
                x = outline[i++] * scale + offsetX;
                y = outline[i++] * scale + offsetY;
                path.moveTo(x, y);
            } else if (action === 'l') {
				x = outline[i++] * scale + offsetX;
				y = outline[i++] * scale + offsetY;
				path.lineTo(x, y);
            } else if (action === 'q') {
                cpx = outline[i++] * scale + offsetX;
                cpy = outline[i++] * scale + offsetY;
                cpx1 = outline[i++] * scale + offsetX;
                cpy1 = outline[i++] * scale + offsetY;
                path.quadraticCurveTo(cpx1, cpy1, cpx, cpy);
            } else if (action === 'b') {
                cpx = outline[i++] * scale + offsetX;
                cpy = outline[i++] * scale + offsetY;
                cpx1 = outline[i++] * scale + offsetX;
                cpy1 = outline[i++] * scale + offsetY;
                cpx2 = outline[i++] * scale + offsetX;
                cpy2 = outline[i++] * scale + offsetY;
                path.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, cpx, cpy);
			}
		}
	}
	return {
        offsetX: glyph.ha * scale, 
        path: path 
    };
}