<script>

	import { Widgets } from './Chevron.vh';
	import ChevronImage from '@/assets/icon-chevron-singleton.svg';
	
	export default {
        name: 'chevron',
		components: {
            // Used to be: <ChevronWrapper class="chevron" :class="$store.state.readingMode ? 'hidden' : 'visible'">
            ChevronWrapper: Widgets.ChevronWrapper, 
			Chevron: Widgets.Chevron
		},
		data: () => ({
			ChevronImage,
            fullBright: false // Whether we are in a running light (default) or all-on (fullBright) mode
		}),
        methods: {
            activate() {
                this.fullBright = true;
            },
            deactivate() {
                this.fullBright = false;
            }
        }
	}

</script>

<style lang="scss" scoped>

    $INNER_MARGIN: 18px;
    $STAGGER_AMOUNT: 0.2s;

    .chevron {
        user-select: none;
        pointer-events: none;
        transition: 1s ease all;
    }

    .chevron-singleton-1 {
        bottom: $INNER_MARGIN*2;
    }

    .chevron-singleton-2 {
        bottom: $INNER_MARGIN;
        animation-delay: $STAGGER_AMOUNT;
    }

    .chevron-singleton-3 {
        bottom: 0px; 
        animation-delay: $STAGGER_AMOUNT*2;
    }

</style>


<template>

    <ChevronWrapper class="chevron">
        <Chevron class="chevron-singleton-1" :src="ChevronImage" :style="fullBright && 'animation: none; opacity: 1'" />
        <Chevron class="chevron-singleton-2" :src="ChevronImage" :style="fullBright && 'animation: none; opacity: 1'" />
        <Chevron class="chevron-singleton-3" :src="ChevronImage" :style="fullBright && 'animation: none; opacity: 1'" />
    </ChevronWrapper>
    
</template>