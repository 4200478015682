import styled from 'vue-styled-components';
import Background from '@/assets/button-z.svg';
let height = 64; //48;
let width = 300;

export var Widgets = {
    CTAButton: styled.div`
        margin-top: 32px;
        user-select: none;
        position: relative;
        height: ${height}px;
        width: ${width}px; // 200px; // 300px;
        overflow: visible; // FENCE: Makes ViewBoxes easier
        line-height: ${height}px;
        /* padding: 12px;
        padding-left: 48px;
        padding-right: 48px; */

        cursor: pointer;
        color: #191919;
        font-weight: bold;
        letter-spacing: 2px;
        // background: #191919; // rgb(10,20,200); // #191919;
        /* background: url(${Background});
        background-repeat: no-repeat;
        background-position: contain;
        background-size:contain; */
        //border-radius: 1000px;
        //box-shadow: 0px 2px 2px rgb(0,20,50);

        /* & > *:first-child {
            z-index: 999:
        } */

        &:hover {
            // background: magenta;
            & > .cta-button-label {
                & > .flicker-base {
                    & > .flicker-overlay {
                        visibility: visible;
                    }
                }
            }
        }

        &:active {
            height: ${height - 2}px;
            // margin-top: 17px;
            // margin-bottom: -3px;
            box-shadow: none;
            background: none;
            text-shadow: 0px -2px magenta, -2px 0px red;
        }


    `
}