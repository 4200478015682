import styled from 'vue-styled-components';

import theme from '@/system/styles/theme';

export var Widgets = {
	DynamicBackground: styled.div`
		transition: background-color 1s;

		z-index: 100;
		width: 100%;
		height: 100%;

		&.light {
			background-color: ${ theme.light.pageColor };
		}

		&.dark {
			background: rgba(0,0,0,1);
			background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, rgba(0,0,30,1) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(20%, rgba(0,0,0,1)), color-stop(80%, rgba(0,0,0,1)), color-stop(100%, rgba(0,0,30,1)));
			background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, rgba(0,0,30,1) 100%);
			background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, rgba(0,0,30,1) 100%);
			background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, rgba(0,0,30,1) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, rgba(0,0,30,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00001e', GradientType=0 );
		}
	`
}