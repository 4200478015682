import styled from 'vue-styled-components';
import DepthMap from '@/system/styles/depth-map';
import PupBoyImage from '@/assets/pup-boy.svg';

export var PupBoy = styled.div`
	transition: all 0.75s;

	position: fixed;
	display: none;
	z-index: ${DepthMap.PUP_BOY};
	left: 0px;
	top: -98vw;
	width: 100vw;
	height: 112.5vw; // Don't depend on phone dimensions for aspect ratio

    background-image: url(${PupBoyImage});
    background: magenta !important; // TODO DELETEME
    background-size: cover;
	justify-content: center;
	align-items: center;
	background-color: white;
	overflow: hidden;
    background-color: transparent;

	&.open {
		top: 0vh;
	}
`;

export var Widgets = {
    StrategyIcon: styled.div`
        float: left;
        width: 100px;
        height: 100px;
        background-color: red;
    `,
    DesignIcon: styled.div`
        float: left;
        width: 100px;
        height: 100px;
        background-color: lime;
    `,
    TechIcon: styled.div`
        float: left;
        width: 100px;
        height: 100px;
        background-color: cyan;
    `,
    AboutIcon: styled.div`
        float: left;
        width: 100px;
        height: 100px;
        background-color: black;
    `,
    FAQIcon: styled.div`
        float: left;
        width: 100px;
        height: 100px;
        background-color: white;
    `,
    PhoneContactIcon: styled.div``,
    EmailContactIcon: styled.div``,
    CollapseToggle: styled.div`
        position: absolute;
        bottom: 10px;
        left: calc(50% - 24px);
        width: 48px;
        height: 32px;
        background: red;
    `
};