export var BecomeHidden = `
	BecomeHidden {
		0% {
			z-index: 100;
			visibility: visible;
		}

		99% {
			z-index: 100;
			visibility: visible;
		}

		100% {
			z-index: -1;
			visibility: hidden;
		}
	}
`;

export var FadeIn = `
	FadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
`;

export var Fly = `
	Fly {
		0% {
			transform: translateZ(-100px) rotate(0deg);
		}
		30% {
			transform: translateZ(0px) rotate(0deg);
		}
		70% {
			transform: translateZ(200px) rotate(0deg);
		}
		100% {
			transform: translateZ(600px) rotate(0deg);
		}
	}
`;

export var Grow = `
	Grow {
		0% {
			height: 0.5vmax;
			width: 0.5vmax;
		}

		60% {
			width: 1vmax;
			height: 1vmax;
			left: calc(50% - 0.5vmax);
			top: calc(50% - 0.5vmax);
			box-shadow: 0px 0px 0px 0px rgba(255,255,255,1);

		}

		70% {
			width: 200vw;
			height: 200vh;
			left: -50vw;
			top: -50vh;
			box-shadow: 0px 0px 150px 50px rgba(255,255,255,0.5);
			background-color: white;
		}

		90% {
			background-color: white;
		}

		100% {
			width: 200vw;
			height: 200vh;
			left: -50vw;
			top: -50vh;
			background-color: rgba(255,255,255,0);
		}
	}
`;

export var FlipUp = `
	FlipUp {
		0% { 
			transform: rotateX(90deg);
		}
		100% {
			transform: rotateX(0deg);
		}
	}
`;

export var FlipDown = `
	FlipDown {
		0% { 
			transform: rotateX(-90deg);
		}
		100% {
			transform: rotateX(0deg);
		}
	}
`;

export var DarkToWhite = `
	DarkToWhite {
		0% {
			background-color: black;
		} 75% {
			background-color: black;
		} 100% {
			background-color: white;
		}
	}
`;

export var DarkToWhiteBorders = {
	Left: `
		DarkToWhiteBordersLeft {
			0% {
				border-left-color: black;
			} 75% {
				border-left-color: black;
			} 100% {
				border-left-color: white;
			}
		}
	`,
	Top: `
		DarkToWhiteBordersTop {
			0% {
				border-top-color: black;
			} 75% {
				border-top-color: black;
			} 100% {
				border-top-color: white;
			}
		}
	`,
	Right: `
		DarkToWhiteBordersRight {
			0% {
				border-right-color: black;
			} 75% {
				border-right-color: black;
			} 100% {
				border-right-color: white;
			}
		}
	`,
	Bottom: `
		DarkToWhiteBordersBottom {
			0% {
				border-bottom-color: black;
			} 75% {
				border-bottom-color: black;
			} 100% {
				border-bottom-color: white;
			}
		}
	`
};