import styled from 'vue-styled-components';
import theme from '@/system/styles/theme';
import DepthMap from '@/system/styles/depth-map';

// Abstract for the quarters
var ROIQuarterButton = styled.div`
		transition: left 0.25s linear, top 0.25s linear, transform 0.1s linear, box-shadow 0.1s linear;
		position: absolute;
		height: 150px;
		width: 150px;
		border: 6px solid rgb(20, 20, 20);

		cursor: pointer;
		text-transform: uppercase;

		&:hover {
			/* box-shadow: 0 5px 10px rgba(0,0,0,0.30), 0 5px 10px rgba(0,0,0,0.50); */
			transform: scale(1.05);
		}

		&.active {
			top: -6px;
			left: -6px;
			z-index: 2000;

			box-shadow: none;

			& > .label {
				opacity: 0;
			}
		}

		&.idle {
		}

		& > .label {
			transition: all 1s;

			position: absolute;

			font-weight: bold;
			letter-spacing: 1px;
			pointer-events: none;
			color: rgb(50,50,50);
			color: white;
			font-family: teko;
			letter-spacing: 1.1px;
			font-size: 26px;
		}
`;

export var ROITray = styled.div`
	transition: all 0.75s;

	position: fixed;
	display: flex;
	z-index: ${DepthMap.TRAYS};
	left: 25vw;
	top: -600px;
	width: 50vw;
	height: 550px;
	min-width: 550px;
    border: 10px solid rgb(20, 20, 20);
    border-top: none;
    border-radius: 0px 0px 24px 24px;

	justify-content: center;
	align-items: center;
	background-color: white;
	overflow: hidden;

	&.visible {
		top: 0vh;
	}

	&.light {
		background-color: ${ theme.light.primaryColor };
		color: ${ theme.light.fontColorContent };
		box-shadow: 0 10px 20px rgba(0,0,0,0.20), 0 5px 10px rgba(0,0,0,0.50);

	}

	&.dark {
		background-color: ${ theme.dark.primaryColor };
		color: ${ theme.dark.fontColorContent };
		box-shadow: 0 10px 20px rgba(0,0,0,0.50), 0 5px 10px rgba(0,0,0,0.80);
	}
`;

export var Widgets = {
	InfoCardScroller: styled.div`
		width: 100%;
		height: 100%;
		overflow: auto;
	`,
	
	ROIPrioritiesHeader: styled.div`
		position: absolute;
		top: 5vh;

		font-size: 30px;
        letter-spacing: 0px;
		line-height: 50px;
		font-family: Arial Black;
		white-space: nowrap;
		overflow: hidden;
	`,

	StrengthQuarter: styled(ROIQuarterButton)`
		left: calc(48.5% - 150px);
		top: calc(48% - 150px);

		border-radius: 100% 0px 0px 0px;
		background-color: rgb(235,75,60);
		

		&.active { transform: rotate(180deg); }

		& > .label {
			bottom: 40px;
			right: 32px;
            color: rgb(255,244,244);
		}
	`,

	VisionQuarter: styled(ROIQuarterButton)`
		left: 51.5%;
		top: calc(48% - 150px);

		border-radius: 0px 100% 0px 0px;
		background-color: rgb(50,150,225);

		&.active { transform: rotate(90deg); }

		& > .label {
			bottom: 40px;
			left: 32px;
            color: rgb(244,244,255);
		}
	`,

	SecurityQuarter: styled(ROIQuarterButton)`
		top: 51.75%;
		left: calc(48.5% - 150px);

		border-radius: 0px 0px 0px 100%;
		background-color: rgb(230,170,0);

		&.active { transform: rotate(-90deg); }

		& > .label {
			top: 40px;
			right: 32px;
            color: rgb(255,255,244);
		}
	`,

	GrowthQuarter: styled(ROIQuarterButton)`
		left: 51.5%;
		top: 51.75%;

		border-radius: 0px 0px 100% 0px;
		background-color: rgb(50,150,50);

		&.active { /* Do nothing, already rotated, abstract component will move it to corner */ }

		& > .label {
			top: 40px;
			left: 32px;
            color: rgb(244,255,244);
		}
	`,

	ROIPrioritiesFooter: styled.div`
		position: absolute;
		bottom: 7.5vh;

		font-size: 20px;
        letter-spacing: .75px;
		font-family: Arial Black;
	`,

	ROIContentLayer: styled.div`
		transition: all 1s;

		position: absolute;
		z-index: 1000;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;

		background-color: white;

		&.visible {
			opacity: 1;
			pointer-events: all;
		}

		&.hidden {
			opacity: 0;
			pointer-events: none;

			& > * {
				opacity: 0;
			}
		}

		& .roi-content-header {
			height: 50px;
			margin-right: 30px;
			margin-top: 20px;

			text-align: right;
			line-height: 45px;
			font-size: 40px;
			text-transform: uppercase;
			font-family: Arial Black;
		}

		& .roi-content-body {
			position: absolute;
			right: 0px;
			bottom: 24px;
			height: calc(100% - 214px);
			width: calc(100% - 200px);
			padding: 50px;

            font-size: 22px;
			line-height: 28px;
			letter-spacing: 0.3px;
			overflow: auto;

			& img {
				max-width: calc(100% - 24px);
				margin-bottom: 24px;
                border: 12px solid rgb(20,20,20);
                border-radius: 3px;
			}

			& button {
				width: 50%;
				height: 50px;
				margin: 50px;

				cursor: not-allowed;
				color: rgb(50,50,50);

				&:active, &:hover {
					background-color: rgb(239,239,239);
					border: 1px solid gray;
					border-radius: 4px;
				}
			}
		}
	`,

	ROIContentHairlineTop: styled.div`
		position: absolute;
		top: 80px;
		right: 30px;
		height: 1px;
		width: calc(100% - 200px);

		background-color: rgb(200,200,200);
	`,

	ROIContentHairlineLeft: styled.div`
		position: absolute;
		bottom: 30px;
		left: 80px;
		height: calc(100% - 200px);
		width: 1px;

		background-color: rgb(200,200,200);
	`,

	ROIContentBackButton: styled.div`
		position: absolute;
		bottom: 30px;
		left: 25px;

		color: silver;
		font-size: 30px;
		cursor: pointer;

		&:hover {
			color: black;
		}
	`,
	
	ScrollBox: styled.div`
		position: relative;
		display: block;
		height: 100%;
		width: 100%;
		
		overflow-y: auto;

		& > h1 {
			margin-bottom: 24px;
		}

		& > img {
			float: right;
			max-width: 40%;
			max-height: 100%;
			margin-left: 24px;

			&.wide {
				max-width: 70%;
			}
		}

		& > p {
			font-size: 20px;
			line-height: 32px;
			font-family: 'quicksand';
		}
	`,
	CollapseToggle: styled.div`
		position: absolute;
		width: 80%;
		height: 32px;
		left: 10%;
		bottom: 0px;
		z-index: 3000;

		cursor: pointer;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center 6px;
		color: black;
		text-align: center;

		& > svg {
			height: 200% !important;
			width: 100% !important;
			margin-top: -10px;

			filter: drop-shadow(0px 0px 1px white);
		}

		&:hover {
			color: blue;

			& > svg {
				filter: none;
			}
		}
	`,

    BackdropScreen: styled.div`
        z-index: -100;
        transition: background-color 1s;
        position: fixed;
        width: 100vw;
        height: 200vh; // So we can animate the whole tray in without seeing the edge
        left: 0px;
        top: 0px;
        cursor: pointer;
        background-color: rgba(0,0,0,0.5);
    `
};