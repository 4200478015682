<script>
    // import store from '@/system/store';
    import awaken from '@/system/tools/Awaken';
    import { Widgets, FLIP_ANIMATION_DURATION } from './MiniCard.vh';

	export default {
        name: 'mini-card',
        props: {
            scrollables: Array,
            frontImage: String, // GOTCHA: This is raw (unimported) data
            backImage: String // GOTCHA: This is raw (unimported) data
        },
        components: {
            MiniCard: Widgets.MiniCard,
            SpinFrame: Widgets.SpinFrame,
            Front: Widgets.Front,
            Back: Widgets.Back
        },
        data: () => ({ 
            flipped: false,
            hovering: false
        }),
        computed: {
            // FENCE:
            // Setting these here gives consistency to the call (user includes path)
            // But Webpack requires a string literal in order to pick up and transpile
            // the image paths, so this hack solves both problems at a compromise.
            frontImagePath: function() {
                let fileName = this.frontImage.split('/').pop();
                return require('@/assets/' + fileName);
            },
            backImagePath: function() {
                let fileName = this.backImage.split('/').pop();
                return require('@/assets/' + fileName);
            }
        },
        mounted() {
            awaken(this, 'scroll', 'ancestors', (scrollable, oldScrollable) => {
                let card = { center: this.$el.offsetTop + this.$el.offsetHeight/2 }; // DEBUG: We know context in <div class="cPXXqs"> immediately under BODY is the missing piece
                let scroll = { progress: scrollable.position + scrollable.context.elm.offsetHeight/2 };
                this.flipped = (card.center < scroll.progress) ? true : false; // "<" means "higher" - blame css
            });
        }
    }

</script>

<template>
    <MiniCard ref="mini-card" 
        style="cursor: no-drop"
        @mouseover="hovering = true"
        @mouseout="hovering = false"
        class="mini-card"
        :class="flipped && 'flipped'">
        <div class="overlay" style="z-index: 9999; pointer-events: none; position: absolute; left: 0px; top: 0px; width: 100%; height: 100%; z-index: 1;">
            <FontAwesome icon="ban" style="font-size: 100px; margin-top: 50px; color: white; filter: drop-shadow(0px 0px 12px white)" />
            <br /> <br />
            <p style="height: 100%; font-size: 18px; color: white; line-height: 24px">Drill-Down Info<br />Coming Soon!</p>
        </div>
        <!-- :style="hovering && 'filter: brightness(10%)'" -->
        <SpinFrame class="frame">
            <Front class="front" :image="frontImagePath" :style="hovering && 'filter: brightness(10%)'" />
            <Back class="back" :image="backImagePath" :style="hovering && 'filter: brightness(10%)'" />
        </SpinFrame>
    </MiniCard>
</template>