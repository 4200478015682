<script>

    // Omitted from flickertext effect (too extreme)
    // 40%  { clip: rect(64px,  9999px,  98px,  0); }
    // 90%  { clip: rect(43px,  9999px,  96px,  0); }

	import { Widgets } from './CTAButton.vh';
	import Background from '@/assets/button-z.svg';
    import '@/system/styles/FlickerText.scss';

	export default {
        name: 'cta-button',
		components: {
			CTAButton: Widgets.CTAButton
		},
        data: () => ({
            Background
        }),
	}

</script>

<style lang="scss" scoped>

    .cta-button-label {
        position: absolute;
        left: 52.5px;
        top: 9.5px;
        z-index:999;
        pointer-events: none;
        line-height: 64px;
    }
    svg {
        pointer-events: all;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;

        &:hover {
            & > path {
                // stroke-dashoffset: 820;
                animation: dash .25s linear forwards;
            }

            & ~ p {
                color: red;
            }
        }

        path {
            // pointer-events: none;
            stroke-dasharray: 820;
            animation: dash-reverse .25s linear forwards;
            // stroke-dashoffset: 0;
            //transition: .25s linear forwards; //infinite;
        }
        
        @keyframes dash {
            from {
                stroke-dashoffset: 0px;
            }
            to {
                stroke-dashoffset: 820;
            }
        }

        @keyframes dash-reverse {
            from {
                stroke-dashoffset: 820;
            }
            to {
                stroke-dashoffset: 0px;
            }
        }
    }

    .flicker-base {
        // FENCE: Overlay text will roam without this
        transform: translateY(0px);
        max-height: 50px !important; // FENCE: If it wraps it relocates and causes a visual error
        overflow: hidden !important; //
    }

    .flicker-text {
        font-family: Arial;
        font-size: 32px !important;
        color: #222222;
        text-transform: uppercase;
        font-weight: 900;
    }

    .flicker-overlay {
        visibility: hidden; // By default we don't flicker
        position: absolute;
        top: 0;
        left: -2px;
        width: 100%;
        color: #222222;
        text-shadow: 2px 0 #F9F8F8, -1px 0 yellow, -2px 0.5px magenta;
        overflow: hidden;
        animation: Flicker 2500ms infinite linear alternate-reverse;
    }    

    @keyframes Flicker {
        0%   { clip: rect(0px,   9999px,  94px,  0); }
        10%  { clip: rect(112px, 9999px,  76px,  0); }
        20%  { clip: rect(85px,  9999px,  77px,  0); }
        30%  { clip: rect(27px,  9999px,  97px,  0); }
        50%  { clip: rect(61px,  9999px,  85px,  0); }
        60%  { clip: rect(99px,  9999px,  114px, 0); }
        70%  { clip: rect(34px,  9999px,  115px, 0); }
        80%  { clip: rect(98px,  9999px,  129px, 0); }
        100% { clip: rect(82px,  9999px,  64px,  0); }
    }
</style>




<template>

    <CTAButton class="cta-button" @click="$emit('click')">
        <div class="cta-button-label">
            <div class="flicker-base">
                <p class="flicker-text">EXPLORE</p>
                <div class='flicker-overlay'>
                    <p class="flicker-text">EXPLORE</p>
                </div>
            </div>
        </div>

        <svg viewBox="60 0 250 80">
            <path d="
                M324.996193,5
                L324.996193,60.3053161
                L306.092623,74.999547
                L5,74.999547
                L5,15.7472603
                L19.3881411,5
                L330,5 Z"
                id="Rectangle-Copy-9"
                stroke="#191919"
                stroke-width="10"
                fill="#FFFFFF55">
            </path>
        </svg>

    </CTAButton>
    
</template>