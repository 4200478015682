<script>

	import { Widgets } from './AboutTab.vh';

	export default {
		name: 'about-tab',
        components: {
            AboutTab: Widgets.AboutTab
        }
	}
    
</script>

<template>

	<AboutTab id="about-tab" :style="!$store.state.roiTrayVisible && $store.state.activeModals.about ? 'height: 65px !important' : ''"
        @bevel-bar-clicked="$store.dispatch('openAboutModal')">
		<FontAwesome icon="paw" />
		<p id="about-tab-label">
            About
		</p>
	</AboutTab>

</template>