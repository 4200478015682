import styled from 'vue-styled-components';

export var Widgets = {
    Logo: styled.div`
        top: 24px;
        margin-top: -24px;
        height: 100px; 
        width: 100%;
        padding-top: 48px;

        font-size: 48px;
        text-align: center;
    `,
    LogoImage: styled.img`
        height: 42px;
        width: auto;
    `,
    SubtitleArea: styled.div`
        width: 100%;
        margin-top: 6px;

        pointer-events: none;
        user-select: none;
        -webkit-user-select: none;
        font-size: 10px;
        font-weight: bold;
        line-height: 16px;
        white-space: nowrap;
        text-align: center;
    `
}