import styled from 'vue-styled-components';
import DepthMap from '@/system/styles/depth-map';
import theme from '@/system/styles/theme';

export function updateVolume(newVolume, volume, audio) {
	audio.volume = newVolume/100;

	if (newVolume > 0 && audio.paused) {
		audio.play();
	}
}

export function focusTopic(topic) {
	// Left this in case we want to have a blunt animating header later w/shrink
    // Ensure the header is static before forcing scroll, or we project wrong destination due to header shrinking as a state-based effect of scrolling the page
	// let demoHeader = document.getElementById('demo-reel');
	// if (demoHeader.getBoundingClientRect().height !== window.innerHeight/2) { // Don't reapply classes infinitely if it's been done
	// 	demoHeader.style.transition = 'all 0s';
	// 	demoHeader.classList.add('small-mode');
	// }

	let target = document.getElementById(topic);
	let targetLocation = target?.offsetTop - 100;
	let container = document.getElementById('router-container');

	if (!container?.scrollTo || !targetLocation) {
		return;
	}

    // As above, we don't jump here anymore
	// When we focus the introduction, don't offset (0th element scenario)
	// if (topic === 'topic-introduction') {
	// 	targetLocation = target?.offsetTop;
	// }
	
	container.scrollTo({
		left: 0,
		top: targetLocation,
		behavior: 'smooth'
	});
}

export const Widgets = {
	App: styled.div`
		height: 100vh;
		width: 100vw;

		overflow: hidden;
	`,
	RouterContainer: styled.div`
		//* z-index: 0;
		//* top: 0vh;
		height: 100%;
		width: 100%;
		//* left: 0vw;
		//* position: fixed;

		text-align: center;
		font-size: 32px;
		color: #777;
		/* letter-spacing: 25px; */
		font-family: Helvetica;
		line-height: 24px;
		overflow: auto;
		//* overflow-x: hidden;

		& p {
			margin-bottom: 12px;

			font-size: 28px;
			font-family: 'quicksand';
			line-height: 44px;
			letter-spacing: 0.75px;
			color: #333;
		}

        & a {
            letter-spacing: -0.25px;
        }

		&.light {
			color: ${ theme.light.fontColorContent };
		}

		&.dark {
			color: ${ theme.dark.fontColorContent };

			& > .router-content-area {
				color: #F0F0F0;

				& > p {
					color: #DDD;
				}
			}
		}

		& > .router-content-area {
			//* position: absolute;
			display: block;
			padding-top: 0px;
		}
	`,
	MenuLayer: styled.div`
		pointer-events: none;
		display: flex;
		justify-content: center;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: ${DepthMap.MENUS};
	`
}