export function syncCanvasSize(canvas, renderer, camera) {
   // const canvas = renderer.domElement;

//    canvas.style.width = '100%';
//    canvas.style.height = '100%';
    // look up the size the canvas is being displayed
    const width = canvas.parentElement.clientWidth;
    const height = canvas.parentElement.clientHeight;
    // console.log(canvas.offsetHeight);
  
    // adjust displayBuffer size to match
    if (canvas.width !== width || canvas.height !== height) {
        renderer.setSize(width, height, true);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
    }
}
  